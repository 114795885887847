<template>
  <svg width="565" height="463" viewBox="0 0 565 463" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity=".47" d="M565 175.264v175.439c-9.024.175-18.282-1.873-25.314-8.603-19.337-18.492-11.134-29.727-29.709-36.164-18.576-6.437-30.647 5.208-47.582-8.134a69.604 69.604 0 0 0-15.059-8.837 117.097 117.097 0 0 0-16.466-5.793c-27.014-7.549-55.961-7.607-55.961-7.607l-1.055-1.873-1.992-3.511-2.168-3.862-3.809-6.671-.996-1.756h4.98s26.135 4.389 49.691-2.692c8.087-2.458 15.88-6.203 22.209-11.996 17.286-15.8 29.943-27.387 58.598-24.871 21.271 1.814 27.717-1.521 35.862-19.135 6.856-15.04 17.813-21.594 28.771-23.934Z" fill="url(#paint0_linear_356_2815)" />
    <path opacity=".47" d="M565 17.029v91.055c-6.329 2.575-22.853 8.134-29.827-1.112-8.672-11.528.879-17.438-9.082-20.891-9.962-3.453-19.806 3.862-28.947-3.336-4.102-3.218-9.493-5.617-15.119-7.373-2.578-.82-5.274-1.463-7.852-1.99a129.263 129.263 0 0 0-24.201-2.633l-.527-3.745-.645-4.33-.175-.995-.704-4.916s22.268 7.022 36.272-1.17c1.172-.703 2.286-1.463 3.282-2.341l.293-.293c9.376-8.485 19.279-15.507 31.994-14.512 11.485.877 14.708-.468 18.283-7.14C545.311 17.79 557.265 16.445 565 17.03Z" fill="url(#paint1_linear_356_2815)" />
    <path opacity=".47" d="M565 21.71v91.055c-6.329 2.575-22.853 8.135-29.827-1.111-8.672-11.529.879-17.439-9.082-20.891-9.962-3.453-19.806 3.862-28.947-3.336-15.177-11.938-47.172-11.997-47.172-11.997l-.293-1.99-.937-6.378-.645-4.389-.176-1.228.704.234c.82.234 2.285.585 4.16.994 6.036 1.288 16.642 2.75 25.783 0 3.399-.994 6.563-2.691 9.2-5.032.762-.702 1.523-1.405 2.285-2.048.938-.82 1.875-1.58 2.813-2.341 7.911-6.379 16.407-10.943 26.838-10.182 11.485.877 14.708-.468 18.282-7.14 7.325-13.459 19.279-14.805 27.014-14.22Z" fill="url(#paint2_linear_356_2815)" />
    <path opacity=".47" d="M565 186.382v173.859c-10.196-.058-20.861-2.341-28.655-9.538-19.63-18.141-9.785-30.138-28.302-36.516-18.517-6.379-31.467 6.203-47.582-8.134-9.61-8.485-23.38-13.811-37.151-17.029-25.138-5.969-50.335-5.208-50.335-5.208l-.352-.585-5.332-9.481-1.992-3.511-2.286-4.037h4.922s.645.117 1.817.234c7.5.819 36.917 3.277 59.477-6.671 2.871-1.229 5.625-2.751 8.262-4.448a47.074 47.074 0 0 0 5.743-4.447c17.286-15.8 31.76-26.041 55.257-24.227 21.33 1.638 32.229 2.516 40.374-15.098 6.563-14.688 16.349-21.886 26.135-25.163Z" fill="url(#paint3_linear_356_2815)" />
    <path d="M564.941 251.63H308.928v33.824h256.013V251.63Z" fill="url(#paint4_linear_356_2815)" />
    <path d="M565 53.252H419.56V75.49H565V53.252Z" fill="url(#paint5_linear_356_2815)" />
    <path d="M564.942 259.589H307.639v10.533h257.303v-10.533Z" fill="url(#paint6_linear_356_2815)" />
    <path d="m137.88 297.86.821.41-2.11.878c.41-.468.879-.878 1.289-1.288Z" fill="#FD563B" />
    <path d="M163.082 263.46c1.379-8.486-4.391-16.482-12.888-17.859-8.498-1.378-16.504 4.385-17.883 12.871-1.38 8.486 4.391 16.482 12.888 17.859 8.498 1.377 16.504-4.385 17.883-12.871Z" fill="#1C0C31" />
    <path d="M303.712 234.133v68.994H126.63c-23.029-1.405-42.952-7.608-59.418-19.253-.938-.643-1.934-1.346-2.872-2.106-4.98-3.804-9.61-8.076-13.77-12.816 19.864-21.242 45.589-34.877 76.822-34.877l176.32.058Z" fill="#CBAFEF" />
    <path d="M307.17 241.682h-3.458v54.306h3.458v-54.306ZM309.748 249.699h-2.578v37.803h2.578v-37.803Z" fill="#9C63CE" />
    <path d="M303.712 255.844c-35.334.409-44.768.234-47.523 0-2.519 2.926-16.758 25.338-25.49 39.324l-.351.527h-72.896l-13.301-11.938c-20.158.059-61.118.234-76.94.059-.937-.644-1.933-1.346-2.87-2.107 12.715.117 60.648 0 80.395-.117h.528l13.302 11.938H229c24.67-39.5 25.9-39.734 26.545-39.851l.527-.117.293.117c2.813.351 29.709.117 47.406-.059l-.059 2.224Z" fill="#F2F2F2" />
    <path d="M186.868 287.502h-3.222v3.219h3.222v-3.219Z" fill="#1C0C31" />
    <path d="M193.138 287.502h-3.222v3.219h3.222v-3.219ZM199.408 287.502h-3.222v3.219h3.222v-3.219Z" fill="#F46B1D" />
    <path d="M205.678 287.502h-3.222v3.219h3.222v-3.219Z" fill="#1C0C31" />
    <path d="M268.378 248.061h-3.223v3.218h3.223v-3.218Z" fill="#F46B1D" />
    <path d="M274.648 248.061h-3.223v3.218h3.223v-3.218Z" fill="#1C0C31" />
    <path d="M280.918 248.061h-3.223v3.218h3.223v-3.218Z" fill="#F46B1D" />
    <path d="M287.188 248.061h-3.223v3.218h3.223v-3.218Z" fill="#1C0C31" />
    <path d="m262.05 263.568-3.809 1.639-42.542-.41v1.58h-.059v4.448h.059v1.58s42.132-1.171 43.304 0c.41.351.82.643 1.289.877a21.212 21.212 0 0 0 10.137 2.575h39.847v-14.629l-39.906.526c-2.871 0-5.684.644-8.32 1.814ZM212.534 303.127s31.584 4.564 41.019 11.938c9.434 7.373 19.923 21.886 25.021 22.471 5.098.585 31.057 2.282 31.057 2.282l1.113-36.691h-98.21Z" fill="#8047B2" />
    <path d="M312.15 303.127h-7.969V342.1h7.969v-38.973Z" fill="#662D91" />
    <path d="M212.534 234.133s31.584-4.564 41.019-11.937c9.434-7.374 19.923-21.886 25.079-22.472 5.157-.585 31.057-2.282 31.057-2.282l1.114 36.691h-98.269Z" fill="#8047B2" />
    <path d="M312.15 195.16h-7.969v38.973h7.969V195.16Z" fill="#662D91" />
    <path d="M102.663 237.118v62.498c-13.126-3.16-25.021-8.368-35.51-15.742-.938-.643-1.934-1.346-2.871-2.106-4.981-3.804-9.61-8.076-13.77-12.816 14.414-15.273 31.818-26.685 52.151-31.834Z" fill="#8047B2" />
    <path d="M312.502 260.233h-7.032v17.204h7.032v-17.204Z" fill="#662D91" />
    <g opacity=".41" fill="#662D91">
      <path opacity=".41" d="M304.181 303.127h-91.647s31.584 4.564 41.019 11.938c9.434 7.373 19.923 21.886 25.021 22.471 3.633.41 17.755 1.346 25.607 1.931v2.633h7.969v-38.973h-7.969ZM312.443 268.835v8.602h-2.695v10.065h-2.637v8.486h-3.457v7.139H126.63c-8.087-.468-16.056-1.639-23.967-3.453-.117 0-.234-.058-.293-.058-1.465-.351-2.93-.702-4.336-1.171a4.923 4.923 0 0 1-.996-.292 36.973 36.973 0 0 1-3.34-1.053c-.469-.176-.996-.352-1.465-.527a24.005 24.005 0 0 1-2.871-1.054l-1.407-.526c-1.113-.41-2.168-.878-3.223-1.346a5.872 5.872 0 0 1-.879-.41 79.807 79.807 0 0 1-3.926-1.814c-.41-.175-.82-.409-1.23-.644l-2.637-1.404c-.469-.293-.938-.527-1.465-.819-.82-.468-1.64-.995-2.461-1.522-.41-.292-.88-.526-1.23-.76-1.231-.761-2.403-1.58-3.634-2.4a32.787 32.787 0 0 1-2.871-2.106 98.038 98.038 0 0 1-13.77-12.757l.175-.176h261.639Z" />
    </g>
    <path d="m307.17 83.623.527.293-1.348.585c.235-.351.528-.644.821-.878Z" fill="#FD563B" />
    <path d="M313.615 69.579c5.632 0 10.196-4.559 10.196-10.182 0-5.624-4.564-10.183-10.196-10.183-5.631 0-10.196 4.559-10.196 10.183 0 5.623 4.565 10.182 10.196 10.182Z" fill="#1C0C31" />
    <path d="M416.103 41.724v45.293H299.786c-15.118-.936-28.244-4.974-39.026-12.64-.645-.468-1.289-.877-1.875-1.346-3.282-2.516-6.329-5.325-9.024-8.426 13.067-13.928 29.943-22.881 50.453-22.881h115.789Z" fill="#CBAFEF" />
    <path d="M418.388 46.698h-2.285v35.638h2.285V46.698ZM420.088 51.965h-1.7v24.811h1.7V51.965Z" fill="#9C63CE" />
    <path d="M416.103 56.06c-23.205.235-29.416.118-31.232 0-1.641 1.932-11.017 16.62-16.759 25.808l-.235.35h-47.933l-8.731-7.84c-13.243.058-40.139.116-50.57.058-.644-.468-1.289-.878-1.875-1.346 8.38.058 39.788 0 52.797-.059h.351l8.731 7.842h46.293c16.173-25.924 16.993-26.1 17.403-26.217l.352-.058.176.117c1.875.234 19.513.117 31.115-.059l.117 1.405Z" fill="#F2F2F2" />
    <path d="M339.34 76.835h-2.11v2.107h2.11v-2.107Z" fill="#1C0C31" />
    <path d="M343.442 76.835h-2.11v2.107h2.11v-2.107ZM347.544 76.835h-2.11v2.107h2.11v-2.107Z" fill="#F46B1D" />
    <path d="M351.704 76.835h-2.109v2.107h2.109v-2.107Z" fill="#1C0C31" />
    <path d="M392.898 50.911h-2.109v2.107h2.109V50.91Z" fill="#F46B1D" />
    <path d="M397 50.911h-2.109v2.107H397V50.91Z" fill="#1C0C31" />
    <path d="M401.102 50.911h-2.11v2.107h2.11V50.91Z" fill="#F46B1D" />
    <path d="M405.262 50.911h-2.109v2.107h2.109V50.91Z" fill="#1C0C31" />
    <path d="m388.738 61.093-2.52 1.112-27.951-.234v1.054h-.059v2.926h.059v1.053s27.658-.76 28.42 0c.234.234.527.41.82.585a13.99 13.99 0 0 0 6.681 1.697h26.193V59.69l-26.193.351c-1.876-.117-3.751.293-5.45 1.053ZM356.157 87.076s20.744 2.984 26.955 7.841c6.212 4.857 13.068 14.396 16.466 14.747 3.399.351 20.392 1.522 20.392 1.522l.762-24.11h-64.575Z" fill="#8047B2" />
    <path d="M421.611 87.076h-5.215v25.572h5.215V87.076Z" fill="#662D91" />
    <path d="M356.157 41.782s20.744-2.984 26.955-7.841c6.212-4.857 13.068-14.396 16.466-14.747 3.399-.351 20.392-1.521 20.392-1.521l.762 24.11h-64.575Z" fill="#8047B2" />
    <path d="M421.611 16.151h-5.215v25.573h5.215V16.15Z" fill="#662D91" />
    <path d="M284.023 43.772v41.022a66.91 66.91 0 0 1-23.322-10.358c-.644-.468-1.289-.878-1.875-1.346-3.281-2.517-6.328-5.325-9.024-8.427 9.434-10.065 20.861-17.555 34.221-20.891Z" fill="#8047B2" />
    <path d="M421.846 58.87h-4.63v11.294h4.63V58.87Z" fill="#662D91" />
    <g opacity=".41" fill="#662D91">
      <path opacity=".41" d="M416.396 87.076h-60.239s20.744 2.984 26.955 7.841c6.212 4.857 13.068 14.396 16.466 14.747 2.403.293 11.661.878 16.818 1.229v1.756h5.215V87.017h-5.215v.059ZM421.845 64.546v5.618h-1.758v6.612h-1.699v5.56h-2.285v4.681H299.786a89.155 89.155 0 0 1-15.704-2.282c-.059 0-.117 0-.176-.058-.937-.235-1.934-.469-2.871-.761a9.579 9.579 0 0 1-.645-.176c-.761-.234-1.465-.468-2.226-.702-.293-.117-.645-.234-.938-.351a64.552 64.552 0 0 1-1.875-.702l-.938-.351-2.109-.878c-.176-.059-.41-.176-.586-.234-.879-.41-1.758-.761-2.579-1.23-.292-.116-.527-.292-.82-.409-.586-.292-1.172-.585-1.758-.936l-.937-.527-1.641-.995a9.895 9.895 0 0 1-.82-.526 79.22 79.22 0 0 1-2.344-1.58c-.645-.468-1.29-.878-1.876-1.346-3.281-2.516-6.328-5.325-9.024-8.427l.118-.117 171.808.117Z" />
    </g>
    <path opacity=".27" d="M101.081 50.502c0 27.21-21.622 49.565-48.87 50.443-.586 0-1.114.058-1.7.058C22.62 100.945 0 78.357 0 50.502 0 22.647 22.619 0 50.511 0c27.893 0 50.57 22.588 50.57 50.502 0 0 0-.059 0 0Z" fill="#955CD6" />
    <path opacity=".74" d="M101.081 50.502c0 27.21-21.623 49.565-48.87 50.443-26.428-8.895-40.667-37.51-31.82-63.961 6.74-19.955 25.198-33.707 46.293-34.35 20.568 6.905 34.397 26.157 34.397 47.868Z" fill="#26163A" />
    <path opacity=".12" d="M371.1 166.076c0 11.879-9.435 21.593-21.271 21.945h-.703c-12.13 0-22.033-9.832-22.033-22.003 0-12.172 9.844-22.003 22.033-22.003 12.129.058 21.974 9.889 21.974 22.061Z" fill="#955CD6" />
    <path opacity=".74" d="M371.1 166.076c0 11.879-9.434 21.593-21.271 21.945-11.544-3.863-17.696-16.327-13.829-27.855 2.93-8.661 10.958-14.63 20.099-14.923a21.92 21.92 0 0 1 15.001 20.833Z" fill="#26163A" />
    <path d="M176.086 119.846a.644.644 0 1 0 .002-1.288.644.644 0 0 0-.002 1.288ZM232.985 7.783a1.112 1.112 0 1 0 .002-2.224 1.112 1.112 0 0 0-.002 2.224ZM9.493 342.042a1.113 1.113 0 1 0 0-2.226 1.113 1.113 0 0 0 0 2.226ZM443.234 178.716a1.522 1.522 0 1 0 0-3.043 1.523 1.523 0 1 0 0 3.043Z" fill="#fff" />
    <path d="M345.669 61.035h-2.637c0-1.58-.586-3.043-1.7-4.155a5.767 5.767 0 0 0-4.102-1.697v-2.692c2.227 0 4.395.878 5.977 2.517a8.031 8.031 0 0 1 2.462 6.027Z" fill="#D17408" />
    <path d="M340.922 59.162c0 .995-.879 1.815-1.875 1.815s-1.817-.878-1.817-1.873c0-.585.293-1.17.821-1.522.762-.468 1.699-.41 2.344.234.351.352.527.82.527 1.346ZM349.946 61.035h-2.637c0-2.692-1.054-5.267-2.93-7.14-1.875-1.872-4.394-2.984-7.09-2.984h-.059V48.22h.059c3.34 0 6.563 1.346 8.966 3.746a13.036 13.036 0 0 1 3.691 9.07ZM197.24 263.919h-4.277c0-2.516-.997-4.915-2.755-6.729a9.447 9.447 0 0 0-6.68-2.751v-4.33c3.633 0 7.149 1.463 9.728 4.038a14.107 14.107 0 0 1 3.984 9.772Z" fill="#D17408" />
    <path d="M189.505 260.935a3 3 0 0 1-2.988 2.984c-1.231 0-2.344-.76-2.754-1.872a3.112 3.112 0 0 1 1.113-3.687c1.172-.819 2.754-.644 3.809.41a3.21 3.21 0 0 1 .82 2.165ZM204.096 263.919h-4.277c0-4.33-1.7-8.543-4.747-11.645a16.106 16.106 0 0 0-11.485-4.798h-.059v-4.331h.059c5.45 0 10.723 2.165 14.532 6.086 3.868 3.921 6.036 9.188 5.977 14.688Z" fill="#D17408" />
    <path d="m391.375 410.977-60.415 9.48-5.039-31.834c18.868-6.203 39.085-9.129 60.414-9.48l5.04 31.834Z" fill="#3A236D" />
    <path d="M565 399.215V463H145.85c47.816-30.605 110.398-53.72 181.477-65.775a625.383 625.383 0 0 1 38.968-5.384c1.934-.234 3.926-.409 5.918-.585a690.73 690.73 0 0 1 15.88-1.404c17.17-1.288 34.69-1.931 52.504-1.99A677.99 677.99 0 0 1 565 399.215Z" fill="#3A236D" />
    <path d="M497.906 402.55c0 .293-.411.527-1.172.819-3.516 1.112-14.24 1.932-26.897 1.932-12.657 0-23.38-.82-26.896-1.932-.762-.234-1.172-.526-1.172-.819 0-1.521 12.599-2.75 28.127-2.75 15.528 0 28.01 1.229 28.01 2.75ZM287.188 429.235c-1.817 3.511-12.481 8.485-25.959 11.937-12.071 3.102-22.794 3.98-27.248 2.575-1.172-.409-1.934-.936-2.109-1.638-.879-3.394 10.84-9.363 26.193-13.284 15.352-3.921 28.478-4.33 29.357-.878.059.41 0 .878-.234 1.288Z" fill="#231247" />
    <path d="M496.734 403.369c-3.516 1.112-14.24 1.932-26.897 1.932-12.657 0-23.38-.82-26.896-1.932 3.516-1.17 14.239-1.989 26.896-1.989s23.381.819 26.897 1.989ZM287.188 429.235c-1.817 3.511-12.482 8.485-25.959 11.938-12.071 3.101-22.795 3.979-27.248 2.574 1.816-3.511 12.481-8.485 25.959-11.937 12.071-3.102 22.794-3.98 27.248-2.575Z" fill="#311D5C" />
    <path d="M565 456.738v6.203H388.445c0-13.868 40.139-25.163 89.654-25.163 41.839.059 76.998 8.135 86.901 18.96Z" fill="#513A82" />
    <path d="M125.634 277.847c8.22 0 14.884-6.655 14.884-14.864s-6.664-14.864-14.884-14.864-14.884 6.655-14.884 14.864 6.664 14.864 14.884 14.864ZM163.195 277.847c8.22 0 14.884-6.655 14.884-14.864s-6.664-14.864-14.884-14.864-14.884 6.655-14.884 14.864 6.664 14.864 14.884 14.864Z" fill="#8047B2" />
    <path d="M125.341 275.155c6.925 0 12.54-5.607 12.54-12.523 0-6.916-5.615-12.523-12.54-12.523-6.926 0-12.54 5.607-12.54 12.523 0 6.916 5.614 12.523 12.54 12.523ZM163.137 275.155c6.925 0 12.54-5.607 12.54-12.523 0-6.916-5.615-12.523-12.54-12.523-6.926 0-12.54 5.607-12.54 12.523 0 6.916 5.614 12.523 12.54 12.523Z" fill="#1C0C31" />
    <path d="M299.142 70.457c5.404 0 9.786-4.376 9.786-9.773 0-5.397-4.382-9.773-9.786-9.773-5.405 0-9.786 4.376-9.786 9.773 0 5.397 4.381 9.773 9.786 9.773ZM323.812 70.457c5.404 0 9.786-4.376 9.786-9.773 0-5.397-4.382-9.773-9.786-9.773-5.405 0-9.786 4.376-9.786 9.773 0 5.397 4.381 9.773 9.786 9.773Z" fill="#8047B2" />
    <path d="M306.929 62.406c1.041-4.403-1.69-8.816-6.099-9.855-4.409-1.04-8.828 1.687-9.869 6.09-1.041 4.403 1.689 8.816 6.099 9.856 4.409 1.04 8.827-1.687 9.869-6.09ZM323.753 68.7c4.531 0 8.204-3.667 8.204-8.192 0-4.524-3.673-8.192-8.204-8.192-4.531 0-8.204 3.668-8.204 8.192 0 4.525 3.673 8.193 8.204 8.193Z" fill="#1C0C31" />
    <path d="M131.201 259.53c1.23 0 2.227-.995 2.227-2.223a2.226 2.226 0 0 0-2.227-2.224c-1.23 0-2.227.996-2.227 2.224 0 1.228.997 2.223 2.227 2.223ZM169.875 259.53c1.23 0 2.227-.995 2.227-2.223a2.226 2.226 0 0 0-2.227-2.224c-1.23 0-2.227.996-2.227 2.224 0 1.228.997 2.223 2.227 2.223ZM304.123 58.987a1.406 1.406 0 1 0 0-2.811 1.406 1.406 0 0 0 0 2.81ZM328.734 58.987a1.405 1.405 0 1 0 .002-2.81 1.405 1.405 0 0 0-.002 2.81Z" fill="#fff" />
    <defs>
      <linearGradient
        id="paint0_linear_356_2815"
        x1="354.23"
        y1="262.232"
        x2="554.676"
        y2="262.947"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FAD13B" />
        <stop offset=".07" stop-color="#F9CB43" />
        <stop offset=".18" stop-color="#F5B959" />
        <stop offset=".32" stop-color="#EF9C7C" />
        <stop offset=".47" stop-color="#E674AD" />
        <stop offset=".49" stop-color="#E56FB3" />
        <stop offset=".94" stop-color="#1C0C31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_356_2815"
        x1="441.687"
        y1="64.129"
        x2="558.919"
        y2="64.551"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FAD13B" />
        <stop offset=".07" stop-color="#F9CB43" />
        <stop offset=".18" stop-color="#F5B959" />
        <stop offset=".32" stop-color="#EF9C7C" />
        <stop offset=".47" stop-color="#E674AD" />
        <stop offset=".49" stop-color="#E56FB3" />
        <stop offset=".94" stop-color="#1C0C31" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_356_2815"
        x1="441.687"
        y1="68.81"
        x2="558.943"
        y2="69.233"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FAD13B" />
        <stop offset=".07" stop-color="#F9CB43" />
        <stop offset=".18" stop-color="#F5B959" />
        <stop offset=".32" stop-color="#EF9C7C" />
        <stop offset=".47" stop-color="#E674AD" />
        <stop offset=".49" stop-color="#E56FB3" />
        <stop offset=".94" stop-color="#1C0C31" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_356_2815"
        x1="352.143"
        y1="272.564"
        x2="554.539"
        y2="273.285"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FAD13B" />
        <stop offset=".07" stop-color="#F9CB43" />
        <stop offset=".18" stop-color="#F5B959" />
        <stop offset=".32" stop-color="#EF9C7C" />
        <stop offset=".47" stop-color="#E674AD" />
        <stop offset=".49" stop-color="#E56FB3" />
        <stop offset=".94" stop-color="#1C0C31" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_356_2815"
        x1="295.339"
        y1="268.046"
        x2="551.47"
        y2="268.962"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FA953B" />
        <stop offset=".1" stop-color="#F99343" />
        <stop offset=".24" stop-color="#F58C59" />
        <stop offset=".43" stop-color="#EF807C" />
        <stop offset=".63" stop-color="#E671AD" />
        <stop offset=".66" stop-color="#E56FB3" />
        <stop offset=".94" stop-color="#1C0C31" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_356_2815"
        x1="411.79"
        y1="64.065"
        x2="557.308"
        y2="64.587"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FA953B" />
        <stop offset=".1" stop-color="#F99343" />
        <stop offset=".24" stop-color="#F58C59" />
        <stop offset=".43" stop-color="#EF807C" />
        <stop offset=".63" stop-color="#E671AD" />
        <stop offset=".66" stop-color="#E56FB3" />
        <stop offset=".94" stop-color="#1C0C31" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_356_2815"
        x1="294.003"
        y1="264.371"
        x2="551.364"
        y2="265.292"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FAD13B" />
        <stop offset=".07" stop-color="#F9CB43" />
        <stop offset=".18" stop-color="#F5B959" />
        <stop offset=".32" stop-color="#EF9C7C" />
        <stop offset=".47" stop-color="#E674AD" />
        <stop offset=".49" stop-color="#E56FB3" />
        <stop offset=".54" stop-color="#E06CB0" />
        <stop offset=".61" stop-color="#D065A6" />
        <stop offset=".68" stop-color="#B75895" />
        <stop offset=".75" stop-color="#93477E" />
        <stop offset=".83" stop-color="#653060" />
        <stop offset=".92" stop-color="#2E153D" />
        <stop offset=".94" stop-color="#1C0C31" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>

<template lang="pug">
.lp-card
  .lp-card__container
    .lp-card__illustration
      IllustrationSvg.lp-card__svg
    .lp-card__content
      h3.lp-card__title
        Icon.lp-card__icon
        template {{ $t('pages.ssr_landing.monetize.title') }}
      i18n.ready__content-text-description(
        tag="p"
        path='pages.ssr_landing.monetize.text'
        class="lp-card__text"
      )
        template(v-slot:findSponsorsLink)
          strong
            Link(:href="$locatedLink(findSponsorsLink)") {{ $t('pages.ssr_landing.monetize.findSponsorsLink') }}

</template>

<script>
import Icon from './svg/Icon'
import IllustrationSvg from './svg/Illustration'

import Link from '@/components/common/Link/Link'

export default {
  components: {
    Icon,
    IllustrationSvg,
    Link
  },

  data () {
    return {
      findSponsorsLink: '/features/podcast-monetization/'
    }
  }
}
</script>

<style lang="scss" scoped>
.lp-card {
  &__container {
    flex-direction: row-reverse;

    @include display-less(tablet) {
      flex-direction: column-reverse;
    }
  }
}
</style>

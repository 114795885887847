<template>
  <svg width="416" height="366" viewBox="0 0 416 366" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_356_2518)">
      <g opacity=".83">
        <path opacity=".35" d="M338.799 49.782c70.619-.792 70.411 64.05 46.64 106.627-22.275 39.89-59.006 39.613-91.558 73.728-41.91 43.921-56.28 73.642-115.82 62.964-58.712-10.513-14.143-61.945-69.246-71.243-28.318-4.774-60.281-17.183-73.2-42.828-14.13-27.991 11.957-96.533 75.431-111.942 49.52-12.028 62.285 28.665 112.357 22.491 47.786-5.897 60.311-39.183 115.396-39.797Z" fill="#662D91" />
      </g>
      <path d="m227.545 120.533 34.93-15.587 27.876 13.274 29.378-36.293 34.832-10.096s37.993-57.189 41.97-60.14" stroke="#B377E8" stroke-width="4" stroke-miterlimit="10" />
      <path opacity=".99" d="M99.115 261.895c31.617 0 57.247-25.668 57.247-57.33 0-31.663-25.63-57.33-57.247-57.33-31.618 0-57.248 25.667-57.248 57.33 0 31.662 25.63 57.33 57.248 57.33Z" fill="#7946AF" />
      <path d="M156.362 203.951a57.374 57.374 0 0 1-16.333 40.716 57.211 57.211 0 0 1-40.299 17.225 57.203 57.203 0 0 1-40.658-16.357 57.37 57.37 0 0 1-17.2-40.357" fill="#4C388F" />
      <path opacity=".99" d="M197.334 195.777c-.729-4.775-17.771-6.462-43.343-5.155a49.42 49.42 0 0 1 .956 3.823c10.901-.251 17.599.559 17.906 2.522.306 1.964-5.975 4.818-16.644 7.905-13.408 3.854-33.765 8.149-56.666 11.709-21.87 3.369-41.67 5.382-55.58 5.854-12.257.424-19.905-.362-20.223-2.455-.319-2.092 6.6-5.087 18.225-8.352a66.123 66.123 0 0 1-.148-2.215c0-.178 0-.356-.03-.534v-1.129C15.914 214.347-.693 221.331.06 226.228c.772 5.007 19.543 6.621 47.235 4.909 15.577-.951 33.986-2.952 53.602-5.977 20.467-3.154 39.341-6.984 54.864-10.954 25.768-6.567 42.326-13.538 41.572-18.429Z" fill="#9566D3" />
      <path d="m156.246 204.172-59.35.417 39.654-43.571a57.182 57.182 0 0 1 14.526 19.436 57.272 57.272 0 0 1 5.17 23.718Z" fill="#7661DB" />
      <path d="M382.271 190.186c1.796-2.786-14.842-16.569-33.986-17.496-13.065-.614-26.313 4.621-25.701 7.487.46 2.154 12.453-.614 29.305 1.804 22.63 3.228 28.954 10.408 30.382 8.205Z" fill="#fff" />
      <path d="M414.995 207.339a68.557 68.557 0 0 0-23.228-40.653 68.38 68.38 0 0 0-43.875-16.219 68.382 68.382 0 0 0-44.033 15.787 68.564 68.564 0 0 0-23.625 40.422 67.566 67.566 0 0 0-.913 17.109c.159 2.283.441 4.591.833 6.898.092.522.19 1.043.295 1.547l.03.19a68.192 68.192 0 0 0 4.29 13.789 65.46 65.46 0 0 0 5.582 10.384 68.343 68.343 0 0 0 16.234 17.287l.343.258a68.409 68.409 0 0 0 15.026 8.426 68.133 68.133 0 0 0 37.301 3.989l.392-.08a68.089 68.089 0 0 0 39.17-22.08 62.682 62.682 0 0 0 2.016-2.4 69.01 69.01 0 0 0 4.621-6.486 68.307 68.307 0 0 0 9.541-48.168Zm-21.08 41.607a55.329 55.329 0 0 1-3.677 5.075l-.527.614a55.788 55.788 0 0 1-9.388 8.8 54.645 54.645 0 0 1-19.751 9.206c-1.182.3-2.384.54-3.597.754a55.08 55.08 0 0 1-38.206-7.307 55.222 55.222 0 0 1-23.889-30.741 54.49 54.49 0 0 1-1.679-6.996 54.112 54.112 0 0 1-.65-5.265 37.49 37.49 0 0 1-.11-1.651 55.338 55.338 0 0 1 4.843-25.291 55.26 55.26 0 0 1 15.759-20.349 55.166 55.166 0 0 1 23.255-10.99 55.108 55.108 0 0 1 25.704.754 55.173 55.173 0 0 1 22.573 12.334 55.272 55.272 0 0 1 14.543 21.238 55.358 55.358 0 0 1 3.356 25.532 55.325 55.325 0 0 1-8.559 24.283Z" fill="#F26A32" />
      <path d="m315.606 273.828-18.624-16.548-81.209 91.664 18.625 16.547 81.208-91.663Z" fill="#DB5699" />
      <path d="M414.995 207.339a68.557 68.557 0 0 0-23.228-40.653 68.38 68.38 0 0 0-43.875-16.219 68.382 68.382 0 0 0-44.033 15.787 68.564 68.564 0 0 0-23.625 40.422 67.566 67.566 0 0 0-.913 17.109c.165 2.283.435 4.585.833 6.898.092.522.19 1.043.295 1.547l.03.19a68.1 68.1 0 0 0 4.29 13.795 65.896 65.896 0 0 0 5.582 10.378 69.891 69.891 0 0 0 3.267 4.572 67.657 67.657 0 0 0 8.205 8.8 64.838 64.838 0 0 0 4.761 3.915l.344.252a65.735 65.735 0 0 0 5.325 3.571 68.151 68.151 0 0 0 47.002 8.844l.392-.074a68.066 68.066 0 0 0 39.17-22.074 67.03 67.03 0 0 0 2.022-2.406 64.85 64.85 0 0 0 2.384-3.148 73.719 73.719 0 0 0 2.237-3.338 68.328 68.328 0 0 0 9.535-48.168Zm-13.347 22.669a55.168 55.168 0 0 1-7.733 18.938 53.393 53.393 0 0 1-3.677 5.075c-.172.215-.355.43-.527.614a55.75 55.75 0 0 1-9.382 8.8 54.645 54.645 0 0 1-19.751 9.206 49.862 49.862 0 0 1-3.597.754 55.127 55.127 0 0 1-38.199-7.319 55.266 55.266 0 0 1-25.575-37.719 57.038 57.038 0 0 1-.656-5.259c-.043-.552-.073-1.105-.104-1.657a55.344 55.344 0 0 1 3.846-22.902 55.28 55.28 0 0 1 12.901-19.298 55.16 55.16 0 0 1 42.662-15.393 55.145 55.145 0 0 1 22.227 6.624 55.223 55.223 0 0 1 17.548 15.184 55.303 55.303 0 0 1 9.769 21.062 55.36 55.36 0 0 1 .267 23.223l-.019.067Z" fill="#821C78" />
      <path d="m315.641 273.825-3.419 3.86-77.783 87.806-9.075-8.064-9.554-8.481 77.771-87.794 3.431-3.872 8.923 7.923 9.706 8.622Z" fill="#821C78" />
      <path d="m391.905 166.817-8.72 9.997a55.16 55.16 0 0 0-28.893-12.643 55.114 55.114 0 0 0-31.09 5.263 55.218 55.218 0 0 0-23.139 21.45 55.324 55.324 0 0 0-7.634 30.637 57.036 57.036 0 0 0 .76 6.916c.403 2.365.964 4.7 1.679 6.99a54.905 54.905 0 0 0 15.651 24.547l-4.578 5.21-4.149 4.744-76.398 87.505-9.553-8.487 77.746-87.794a66.936 66.936 0 0 1-3.266-4.572 65.95 65.95 0 0 1-5.577-10.365 68.16 68.16 0 0 1-4.289-13.795l-.031-.19a54.559 54.559 0 0 1-.294-1.547 70.747 70.747 0 0 1-.834-6.898 67.62 67.62 0 0 1 .944-17.109 68.583 68.583 0 0 1 15.187-32.057 68.428 68.428 0 0 1 29.158-20.157 68.346 68.346 0 0 1 35.316-2.856 68.407 68.407 0 0 1 32.01 15.211h-.006Z" fill="#E69266" />
      <path d="M234.854 365.051c2.032-2.294-.519-7.885-5.699-12.487-5.179-4.602-11.026-6.473-13.059-4.179-2.033 2.295.519 7.886 5.698 12.488 5.18 4.602 11.027 6.473 13.06 4.178Z" fill="#821C78" />
      <path d="M128.504 42.866a.288.288 0 1 0 0-.577.288.288 0 0 0 0 .577Z" fill="#E1353F" />
      <path d="M131.446 81.828a.386.386 0 1 0 0-.772.386.386 0 0 0 0 .772Z" fill="#A548F0" />
      <path d="M97.276 58.742H82.452v34.495h14.824V58.742Z" fill="#7350B0" />
      <path opacity=".99" d="M116.451 4.455h-14.824v89.217h14.824V4.455Z" fill="#764A9E" />
      <path d="M135.552 31.445v61.792h-14.824V31.445c0-.303.018-.607.055-.908h14.707c.043.3.063.604.062.908Z" fill="#E69266" />
      <path d="M154.879 18.38v74.869h-14.823v-75.28h14.799c.024.135.024.27.024.41Z" fill="#7350B0" />
      <path d="M173.711 31.058v62.179h-14.824V31.058c0-.386 0-.76.043-1.135h14.738c.031.374.043.749.043 1.135Z" fill="#DB5699" />
      <path d="M192.659 53.2v40.037h-14.824V53.2a5.653 5.653 0 0 1 .043-.724h14.707c.04.24.065.481.074.724Z" fill="#836EC8" />
      <path d="M152.986 68.051h-1.851v2.4h1.851v-2.4Z" fill="url(#paint0_linear_356_2518)" />
      <path d="M125.189 50.72h-1.851v2.4h1.851v-2.4Z" fill="url(#paint1_linear_356_2518)" />
      <path d="M152.986 71.377h-1.851v2.4h1.851v-2.4ZM87.11 83.737h-2.016v2.614h2.016v-2.614ZM95.769 72.464h-2.017v2.614h2.017v-2.614Z" fill="#26163A" />
      <path d="M95.769 68.56h-2.017v2.615h2.017V68.56Z" fill="url(#paint2_linear_356_2518)" />
      <path d="M164.255 68.352h-2.341v3.038h2.341v-3.038ZM182.51 79.54h-2.341v3.037h2.341v-3.038ZM172.344 54.084h-2.341v3.037h2.341v-3.037ZM172.344 46.621h-2.341v3.038h2.341v-3.038ZM106.419 63.872h-2.341v3.038h2.341v-3.038ZM114.655 39.38h-2.341v3.038h2.341V39.38Z" fill="#26163A" />
      <path d="M193.774 69.868h-17.011v1.153h17.011v-1.153ZM193.774 72.464h-17.011v1.153h17.011v-1.153ZM193.774 75.06h-17.011v1.153h17.011V75.06Z" fill="#3A236D" />
      <path d="M101.547 6.002a4.678 4.678 0 0 1 .613-2.529 6.257 6.257 0 0 1 1.801-1.939 9 9 0 0 1 10.05 0 6.248 6.248 0 0 1 1.802 1.94c.443.766.656 1.644.613 2.528a5.458 5.458 0 0 0-2.629-4.173 9.256 9.256 0 0 0-9.646 0 5.455 5.455 0 0 0-2.604 4.173ZM177.792 53.317a4.66 4.66 0 0 1 .613-2.529 6.233 6.233 0 0 1 1.795-1.939 9 9 0 0 1 10.05 0 6.262 6.262 0 0 1 1.802 1.94c.44.767.652 1.644.613 2.528a5.465 5.465 0 0 0-2.636-4.173 9.252 9.252 0 0 0-9.645 0 5.467 5.467 0 0 0-2.592 4.173Z" fill="#F2F2F2" />
      <path d="M193.578 52.47h-16.815v1.614h16.815V52.47Z" fill="#3A236D" />
      <path d="M117.45 4.136h-16.816v3.16h16.816v-3.16Z" fill="#4E3782" />
      <path d="M227.539 124.627a4.097 4.097 0 0 0 4.094-4.1 4.096 4.096 0 0 0-4.094-4.099 4.096 4.096 0 0 0-4.093 4.099c0 2.264 1.833 4.1 4.093 4.1ZM261.629 110.174a4.096 4.096 0 0 0 4.094-4.099 4.096 4.096 0 0 0-4.094-4.099 4.096 4.096 0 0 0-4.093 4.099 4.096 4.096 0 0 0 4.093 4.099ZM289.959 120.527a4.096 4.096 0 0 0 4.094-4.099c0-2.264-1.833-4.1-4.094-4.1a4.096 4.096 0 0 0-4.093 4.1 4.096 4.096 0 0 0 4.093 4.099ZM396.501 15.802a4.096 4.096 0 0 0 4.093-4.1c0-2.263-1.832-4.098-4.093-4.098a4.097 4.097 0 0 0-4.094 4.099c0 2.264 1.833 4.1 4.094 4.1ZM352.869 77.416a4.097 4.097 0 0 0 4.094-4.1c0-2.264-1.833-4.099-4.094-4.099a4.096 4.096 0 0 0-4.093 4.1c0 2.264 1.833 4.099 4.093 4.099ZM319.735 86.738a4.097 4.097 0 0 0 4.094-4.1c0-2.264-1.833-4.099-4.094-4.099a4.096 4.096 0 0 0-4.093 4.1c0 2.264 1.832 4.099 4.093 4.099Z" fill="#fff" />
      <path d="M195.294 90.622H78.58v3.198h116.714v-3.198Z" fill="#7350B0" />
      <path d="M248 172.5c-14.609 1.08-15.233 1.957-15.989 22.5-.77-20.543-1.38-21.429-16.011-22.5 14.609-1.071 15.23-1.957 16.011-22.5.756 20.543 1.38 21.429 15.989 22.5ZM55 285.5c-9.13.696-9.52 1.261-9.993 14.5-.481-13.239-.863-13.81-10.007-14.5 9.13-.69 9.519-1.261 10.007-14.5.473 13.239.863 13.81 9.993 14.5Z" fill="#fff" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_356_2518"
        x1="151.135"
        y1="69.248"
        x2="152.98"
        y2="69.248"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FB8338" />
        <stop offset=".14" stop-color="#FB7D41" />
        <stop offset=".38" stop-color="#FA6D5A" />
        <stop offset=".68" stop-color="#F95483" />
        <stop offset="1" stop-color="#F733B6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_356_2518"
        x1="495.821"
        y1="253.718"
        x2="501.41"
        y2="253.718"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FB8338" />
        <stop offset=".14" stop-color="#FB7D41" />
        <stop offset=".38" stop-color="#FA6D5A" />
        <stop offset=".68" stop-color="#F95483" />
        <stop offset="1" stop-color="#F733B6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_356_2518"
        x1="402.198"
        y1="366.197"
        x2="408.831"
        y2="366.197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FB8338" />
        <stop offset=".14" stop-color="#FB7D41" />
        <stop offset=".38" stop-color="#FA6D5A" />
        <stop offset=".68" stop-color="#F95483" />
        <stop offset="1" stop-color="#F733B6" />
      </linearGradient>
      <clipPath id="clip0_356_2518">
        <path fill="#fff" d="M0 0h416v366H0z" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>

<template>
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity=".6" cx="20" cy="20.5" r="20" fill="url(#paint0_linear_356_1823)" />
    <path d="M12 24.899v-.922c0-.162.06-.303.182-.424a.58.58 0 0 1 .441-.196h3.146c.162 0 .304.06.426.181.131.121.197.267.197.439v.922c0 .725.259 1.33.775 1.814.517.483 1.262.725 2.234.725.993 0 1.753-.241 2.28-.725a2.34 2.34 0 0 0 .805-1.814c0-.665-.243-1.174-.73-1.527-.486-.363-1.321-.68-2.507-.952a16.015 16.015 0 0 1-2.735-.817 8.469 8.469 0 0 1-2.22-1.284 5.702 5.702 0 0 1-1.473-1.875c-.365-.735-.547-1.597-.547-2.585 0-.725.111-1.41.334-2.056.233-.655.552-1.25.957-1.783a6.241 6.241 0 0 1 1.475-1.39 7.882 7.882 0 0 1 1.945-.938c.142-.04.258-.09.35-.151.09-.06.136-.162.136-.303V8.12c0-.161.06-.303.183-.423a.612.612 0 0 1 .44-.197h2.645c.162 0 .304.066.425.197.132.12.198.262.198.423v1.058c0 .222.157.383.47.484.71.231 1.353.544 1.93.937a6.364 6.364 0 0 1 1.49 1.406c.416.544.735 1.143.958 1.798.233.645.35 1.33.35 2.056v.469a.63.63 0 0 1-.183.438.602.602 0 0 1-.44.182h-3.132a.637.637 0 0 1-.44-.182.63.63 0 0 1-.183-.438v-.469c0-.614-.243-1.154-.73-1.617-.475-.474-1.16-.71-2.05-.71-.872 0-1.54.236-2.007.71-.466.463-.699 1.003-.699 1.617 0 .595.258 1.048.775 1.36.527.313 1.19.55 1.991.711a21.78 21.78 0 0 1 2.994.862c.932.332 1.727.776 2.386 1.33a5.868 5.868 0 0 1 1.58 1.995c.386.787.578 1.714.578 2.782 0 1.673-.476 3.068-1.429 4.187-.952 1.109-2.198 1.87-3.738 2.283-.162.04-.284.09-.365.15-.07.061-.106.167-.106.318v1.043c0 .161-.061.303-.183.423a.58.58 0 0 1-.44.197h-2.645a.636.636 0 0 1-.44-.181.595.595 0 0 1-.183-.439v-1.073c0-.141-.046-.247-.137-.317a1.253 1.253 0 0 0-.35-.152 8.129 8.129 0 0 1-2.05-.952 6.924 6.924 0 0 1-1.566-1.451 6.787 6.787 0 0 1-1.018-1.86A6.716 6.716 0 0 1 12 24.9Z" fill="url(#paint1_linear_356_1823)" />
    <defs>
      <linearGradient
        id="paint0_linear_356_1823"
        x1="0"
        y1="40.5"
        x2="40"
        y2="40.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F87900" />
        <stop offset=".511" stop-color="#FE4F45" />
        <stop offset="1" stop-color="#E419BA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_356_1823"
        x1="20.5"
        y1="22"
        x2="33"
        y2="9.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F87900" />
        <stop offset=".511" stop-color="#FE4F45" />
        <stop offset="1" stop-color="#E419BA" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<template>
  <svg width="460" height="315" viewBox="0 0 460 315" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_469_2577"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="460"
      height="315"
    >
      <path fill="url(#paint0_linear_469_2577)" d="M0 0h460v315H0z" />
    </mask>
    <g mask="url(#mask0_469_2577)">
      <mask
        id="mask1_469_2577"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="-22"
        y="6"
        width="468"
        height="330"
      >
        <path fill="url(#paint1_linear_469_2577)" d="M-22 6h468v330H-22z" />
      </mask>
      <g mask="url(#mask1_469_2577)">
        <path opacity=".38" d="M426.115 94.81c-.385 48.511-3.895 156.981-6.048 219.956H1.012v-33.822a118.954 118.954 0 0 0 44.52-9.085 112.782 112.782 0 0 0 5.51-2.477 55.89 55.89 0 0 0 10.648-6.929c2.749-2.205 5.448-4.649 8.176-7.23 4.901-4.646 9.882-9.712 15.317-14.572 6.446-5.748 13.554-11.203 21.975-15.311 6.623-3.229 14.58-5.327 23.005-7.148 11.14-2.403 23.096-4.311 33.839-7.697a89.388 89.388 0 0 0 6.549-2.333 66.684 66.684 0 0 0 4.504-1.999 25.918 25.918 0 0 0 6.189-4.315c3.25-3.002 5.887-6.668 8.569-10.302 1.656-2.263 3.354-4.513 5.217-6.582.153-.173.31-.343.467-.512a28.633 28.633 0 0 1 8.396-6.466l3.084-1.512 6.123-3.006 2.691-1.321.232-.112 9.368-4.583 2.608-1.28.067-.033.157-.079.037-.02.571-.281h.025l5.142-2.535 7.336-3.601 6.582-3.229 6.992-3.427 24.947-12.248 5.725-2.808 9.596-4.707 15.318-7.519 3.883-1.904 9.72-4.74 2-.979 8.18-4.005c52.576-25.733 90.062-43.96 91.118-43.935.646.012.84 9.353.72 24.689Z" fill="url(#paint2_linear_469_2577)" />
        <path opacity=".38" d="M426.115 94.81c-.385 48.511-3.895 156.981-6.048 219.956H1.012v-33.822a118.954 118.954 0 0 0 44.52-9.085 112.782 112.782 0 0 0 5.51-2.477 55.89 55.89 0 0 0 10.648-6.929c2.749-2.205 5.448-4.649 8.176-7.23 4.901-4.646 9.882-9.712 15.317-14.572 6.446-5.748 13.554-11.203 21.975-15.311 6.623-3.229 14.58-5.327 23.005-7.148 11.14-2.403 23.096-4.311 33.839-7.697a89.388 89.388 0 0 0 6.549-2.333 66.684 66.684 0 0 0 4.504-1.999 25.918 25.918 0 0 0 6.189-4.315c3.25-3.002 5.887-6.668 8.569-10.302 1.656-2.263 3.354-4.513 5.217-6.582.153-.173.31-.343.467-.512a28.633 28.633 0 0 1 8.396-6.466l3.084-1.512 6.123-3.006 2.691-1.321.232-.112 9.368-4.583 2.608-1.28.067-.033.157-.079.037-.02.571-.281h.025l5.142-2.535 7.336-3.601 6.582-3.229 6.992-3.427 24.947-12.248 5.725-2.808 9.596-4.707 15.318-7.519 3.883-1.904 9.72-4.74 2-.979 8.18-4.005c52.576-25.733 90.062-43.96 91.118-43.935.646.012.84 9.353.72 24.689Z" fill="url(#paint3_linear_469_2577)" />
        <path opacity=".49" d="M80.299 294.517c24.811 0 44.925-1.894 44.925-4.229s-20.114-4.228-44.925-4.228c-24.812 0-44.926 1.893-44.926 4.228s20.114 4.229 44.926 4.229Z" fill="#26163A" />
        <path d="M327.347 39H79.04c-3.827 0-6.93 3.095-6.93 6.912v165.372c0 3.817 3.103 6.912 6.93 6.912h248.307c3.828 0 6.93-3.095 6.93-6.912V45.912c0-3.817-3.102-6.912-6.93-6.912Z" fill="#704391" />
        <path d="M320.247 45.099H83.958c-3.23 0-5.85 2.612-5.85 5.835v153.735c0 3.222 2.62 5.834 5.85 5.834h236.289c3.231 0 5.85-2.612 5.85-5.834V50.933c0-3.222-2.619-5.834-5.85-5.834Z" fill="#9D6DD1" />
        <path d="M318.728 47.816H85.821c-2.965 0-5.37 2.398-5.37 5.355v149.64c0 2.957 2.405 5.355 5.37 5.355h232.907c2.966 0 5.37-2.398 5.37-5.355V53.171c0-2.957-2.404-5.355-5.37-5.355Z" fill="#3A236D" />
        <path d="M306.818 152.979h-62.325a3.155 3.155 0 0 0-3.159 3.151v14.638c0 1.74 1.414 3.15 3.159 3.15h62.325a3.155 3.155 0 0 0 3.159-3.15V156.13a3.156 3.156 0 0 0-3.159-3.151Z" fill="#A489E6" />
        <path d="M245.97 162.088s-8.018 12.623-5.957 14.221c2.062 1.598 21.113-10.269 21.113-10.269l-15.156-3.952ZM236.064 170.83a14.972 14.972 0 0 1-2.563 8.394l-.232.327a15.108 15.108 0 0 1-3.311 3.381l-.133.095a15.32 15.32 0 0 1-1.78 1.107c-.232.124-.472.24-.708.355-.616.281-1.25.524-1.896.727l-.315.091c-.252.07-.509.132-.766.19a15.09 15.09 0 0 1-12.394-2.618l-.182-.141a14.772 14.772 0 0 1-3.085-3.2l-.236-.338a15.022 15.022 0 0 1-1.666-13.425 15.048 15.048 0 0 1 3.797-5.829 15.1 15.1 0 0 1 12.977-3.933 15.12 15.12 0 0 1 6.407 2.736 15.06 15.06 0 0 1 4.489 5.318 15.01 15.01 0 0 1 1.613 6.763h-.016Z" fill="#A489E6" />
        <path d="M302.794 159.35h-54.273a.997.997 0 0 0-.998.996c0 .549.447.995.998.995h54.273a.997.997 0 0 0 .998-.995.997.997 0 0 0-.998-.996ZM271.455 164.822h-22.934a.996.996 0 0 0-.998.995c0 .549.447.995.998.995h22.934a.997.997 0 0 0 .998-.995.996.996 0 0 0-.998-.995Z" fill="#EEF6FF" style="mix-blend-mode:hard-light" />
        <path d="M213.481 165.813h-1.639a.351.351 0 0 0-.352.351v5.636c0 .194.157.351.352.351h1.639a.352.352 0 0 0 .352-.351v-5.636a.352.352 0 0 0-.352-.351ZM229.949 166.052h-1.639a.352.352 0 0 0-.352.351v5.637c0 .193.158.351.352.351h1.639a.352.352 0 0 0 .352-.351v-5.637a.351.351 0 0 0-.352-.351Z" fill="#0A1A5B" />
        <path d="m212.417 176.85 1.37-.116.952 1.153H226.84l2.517-1.33c.199-.145-.414-.07-.228 0 .187.07.712.9.828.917 1.275.202 2.397 1.271 3.312 2.11a15.07 15.07 0 0 1-3.312 3.381l-.132.095c-.568.409-1.162.779-1.78 1.107-.232.124-.472.24-.708.355a16.28 16.28 0 0 1-1.896.727l-.315.091c-.252.07-.509.132-.766.19a15.11 15.11 0 0 1-6.486.054 15.075 15.075 0 0 1-5.908-2.672l-.183-.141a14.79 14.79 0 0 1-3.084-3.2c.849-.78 1.909-1.833 3.084-2.064l.634-.657Z" fill="url(#paint4_linear_469_2577)" />
        <path d="M211.767 177.482v5.256a14.744 14.744 0 0 1-3.084-3.2l-.236-.338a5.59 5.59 0 0 1 3.32-1.718ZM233.501 179.224l-.232.327a15.108 15.108 0 0 1-3.311 3.381v-5.496h.024c.098.008.195.022.29.042a5.464 5.464 0 0 1 1.66.549l.158.083c.437.24.84.537 1.2.883l.12.116a.836.836 0 0 1 .091.115Z" fill="#543896" />
        <path d="M228.881 166.267c0-3.481-3.531-6.301-7.865-6.301-4.335 0-7.866 2.82-7.866 6.301v12.189h15.698v-5.657c.04-.23.061-.464.062-.698v-5.413c-.037-.14-.029-.277-.029-.421Z" fill="url(#paint5_linear_469_2577)" />
        <path d="M228.881 165.569c0-2.713-2.716-4.976-6.317-5.492 1.738 1.028 2.844 2.577 2.844 4.307v5.186c0 .208-.021.416-.062.62v5.033h-12.225v1.185h15.698v-5.033c.04-.206.06-.415.062-.624v-4.81c-.008-.124 0-.24 0-.372Z" fill="#B6B8D1" style="mix-blend-mode:multiply" opacity=".31" />
        <path d="M228.364 166.911c0 3.848-1.946 5.736-4.686 6.404-.88.204-1.78.303-2.683.294-4.065 0-7.361-1.689-7.361-6.698 0-3.25 3.312-5.884 7.365-5.884.349 0 .697.02 1.043.062 3.573.4 6.322 2.857 6.322 5.822Z" fill="#1B1464" />
        <path d="M228.07 166.87c0 3.716-1.888 5.541-4.554 6.194-.853.196-1.725.292-2.6.285-3.945 0-7.137-1.631-7.137-6.475 0-3.142 3.196-5.69 7.141-5.69.336 0 .672.019 1.006.058 3.465.384 6.144 2.758 6.144 5.628Z" fill="url(#paint6_linear_469_2577)" />
        <path opacity=".58" d="M227.047 165.458c-1.3-2.164-4.554-2.152-6.135-2.011-3.577.309-6.504 2.093-6.504 4.678.001.185.016.37.046.553-.749-2.407.256-5.017 3.27-6.305 3.246-1.387 8.189-.186 9.191 2.564.065.168.11.342.132.521Z" fill="url(#paint7_linear_469_2577)" />
        <path d="M227.047 165.458c-1.3-2.164-4.554-2.152-6.135-2.011-3.577.309-6.504 2.093-6.504 4.678.001.185.016.37.046.553-.749-2.407.256-5.017 3.27-6.305 3.246-1.387 8.189-.186 9.191 2.564.065.168.11.342.132.521Z" fill="#7A63AF" />
        <path d="M216.787 164.05c.436-.339.715-.71.622-.829-.092-.118-.521.06-.958.399-.436.339-.715.711-.622.829.092.119.521-.06.958-.399Z" fill="#E9ECF4" />
        <path d="M229.639 174.98h-17.396a.132.132 0 0 0-.132.132v.623c0 .073.059.132.132.132h17.396a.132.132 0 0 0 .132-.132v-.623a.132.132 0 0 0-.132-.132Z" fill="#C3CBDD" />
        <path d="M230.259 175.863h-18.401a.199.199 0 0 0-.199.198v.925c0 .11.089.199.199.199h18.401c.11 0 .199-.089.199-.199v-.925a.199.199 0 0 0-.199-.198Z" fill="#C3CBDD" />
        <path d="M212.74 175.896c1.358-.062 2.72-.091 4.082-.115 1.362-.025 2.724-.025 4.086-.038 1.362-.012 2.724 0 4.086.034 1.362.033 2.724.057 4.086.119-1.362.066-2.724.095-4.086.12-1.362.025-2.724.025-4.086.037-1.362.013-2.724 0-4.086-.037s-2.724-.054-4.082-.12ZM212.74 177.197c1.358-.066 2.72-.095 4.082-.12 1.362-.025 2.724-.025 4.086-.037 1.362-.012 2.724 0 4.086.037s2.724.054 4.086.12a150.61 150.61 0 0 1-4.086.12c-1.362.024-2.724.02-4.086.033-1.362.012-2.724 0-4.086-.037-1.362-.038-2.724-.05-4.082-.116Z" fill="#662D91" />
        <path d="M228.62 181.392h-4.554a.154.154 0 0 0-.153.153v.714c0 .085.069.153.153.153h4.554a.152.152 0 0 0 .153-.153v-.714a.153.153 0 0 0-.153-.153ZM228.62 179.947h-4.554a.153.153 0 0 0-.153.153v.714c0 .085.069.153.153.153h4.554a.152.152 0 0 0 .153-.153v-.714a.152.152 0 0 0-.153-.153ZM228.028 184.134c-.232.124-.472.24-.708.355-.616.281-1.249.524-1.896.727l-.314.091c-.253.07-.51.132-.766.19a2.037 2.037 0 0 1-.025-.281c0-.447.145-.882.414-1.239a1.992 1.992 0 0 1 1.694-.809 2 2 0 0 1 1.618.954l-.017.012Z" fill="#9071D6" />
        <path d="M227.321 184.489c-.617.281-1.25.524-1.896.727l-.315.091a1.353 1.353 0 0 1 .083-.541 1.208 1.208 0 0 1 .983-.773 1.222 1.222 0 0 1 1.149.496h-.004Z" fill="#543896" />
        <path d="M187.632 65.113h-85.475c-3.36 0-6.082 2.716-6.082 6.066v19.54c0 3.35 2.723 6.065 6.082 6.065h85.475c3.358 0 6.081-2.715 6.081-6.065v-19.54c0-3.35-2.723-6.066-6.081-6.066Z" fill="#A489E6" />
        <path d="M130.788 65.208H95.827V96.88h34.961V65.21Z" fill="#6348B6" />
        <path d="M123.1 79.916V96.81h-19.739V79.917c0-5.228 4.418-9.465 9.87-9.465 5.452 0 9.869 4.237 9.869 9.465Z" fill="#C8A6FF" />
        <path d="M114.783 83.942c0 .715-.803 1.289-1.796 1.289-.994 0-1.797-.574-1.797-1.288h3.593Z" fill="#001666" />
        <path d="M113.036 82.894c1.93 0 3.494-1.5 3.494-3.35 0-1.849-1.564-3.348-3.494-3.348-1.93 0-3.494 1.5-3.494 3.349 0 1.85 1.564 3.349 3.494 3.349Z" fill="#fff" />
        <path d="M113.036 81.725c1.258 0 2.277-.976 2.277-2.18 0-1.204-1.019-2.18-2.277-2.18-1.257 0-2.277.976-2.277 2.18 0 1.204 1.02 2.18 2.277 2.18Z" fill="url(#paint8_linear_469_2577)" />
        <path d="m109.385 75.028-2.6-6.343a.892.892 0 0 0 .165-.516.944.944 0 0 0-1.016-.981.951.951 0 0 0-.813.597.948.948 0 0 0 .637 1.26l2.612 6.362 1.015-.38Z" fill="url(#paint9_linear_469_2577)" />
        <path d="M121.697 68.512a.975.975 0 0 0-1.636-.645.966.966 0 0 0-.306.645.864.864 0 0 0 .137.47l-3.279 6.636.985.446 3.279-6.636a.942.942 0 0 0 .82-.916Z" fill="url(#paint10_linear_469_2577)" />
        <path d="M107.828 84.128c1.235 0 2.236-.366 2.236-.817 0-.452-1.001-.818-2.236-.818-1.234 0-2.235.366-2.235.818 0 .451 1.001.817 2.235.817ZM117.822 84.128c1.235 0 2.235-.366 2.235-.817 0-.452-1-.818-2.235-.818-1.235 0-2.236.366-2.236.818 0 .451 1.001.817 2.236.817Z" fill="#684BE0" />
        <path d="M113.231 70.452c-.768.001-1.533.087-2.281.256 4.632.777 8.155 4.654 8.155 9.32v16.781h3.995V79.917c0-5.228-4.421-9.465-9.869-9.465Z" fill="#B6B8D1" style="mix-blend-mode:multiply" opacity=".31" />
        <path d="M184.448 71.418h-45.919a.77.77 0 0 0-.77.769v1.383a.77.77 0 0 0 .77.768h45.919c.426 0 .77-.344.77-.768v-1.383a.769.769 0 0 0-.77-.769ZM184.448 77.03h-45.919a.77.77 0 0 0-.77.768v1.383a.77.77 0 0 0 .77.769h45.919c.426 0 .77-.344.77-.769v-1.383a.768.768 0 0 0-.77-.768ZM184.448 82.807h-45.919a.77.77 0 0 0-.77.768v1.383a.77.77 0 0 0 .77.768h45.919c.426 0 .77-.344.77-.768v-1.383a.769.769 0 0 0-.77-.768ZM184.448 88.419h-45.919a.77.77 0 0 0-.77.768v1.383a.77.77 0 0 0 .77.768h45.919c.426 0 .77-.344.77-.768v-1.383a.768.768 0 0 0-.77-.768Z" fill="#D2E6FF" />
        <path d="M155.59 71.418h-17.061a.77.77 0 0 0-.77.769v1.383a.77.77 0 0 0 .77.768h17.061a.77.77 0 0 0 .77-.768v-1.383a.77.77 0 0 0-.77-.769ZM169.272 77.03h-30.743a.77.77 0 0 0-.77.768v1.383a.77.77 0 0 0 .77.769h30.743a.77.77 0 0 0 .77-.769v-1.383a.77.77 0 0 0-.77-.768ZM173.325 82.807h-34.796a.77.77 0 0 0-.77.768v1.383a.77.77 0 0 0 .77.768h34.796a.77.77 0 0 0 .77-.768v-1.383a.77.77 0 0 0-.77-.768ZM151.715 88.419h-13.186a.77.77 0 0 0-.77.768v1.383a.77.77 0 0 0 .77.768h13.186a.77.77 0 0 0 .77-.768v-1.383a.77.77 0 0 0-.77-.768Z" fill="#6348B6" />
        <path d="M189.598 111.914h-88.319c-3.267 0-5.916 2.642-5.916 5.901v67.847c0 3.259 2.649 5.901 5.916 5.901h88.319c3.268 0 5.916-2.642 5.916-5.901v-67.847c0-3.259-2.648-5.901-5.916-5.901Z" fill="#8047B2" />
        <path d="M188.58 127.683h-87.549v54.874h87.549v-54.874Z" fill="#E0D6FF" stroke="#AE6BE8" stroke-miterlimit="10" />
        <path d="M100.782 162.01s13.372 4.15 41.527-11.054c22.032-11.901 46.316-7.292 46.316-7.292l.145 38.814h-87.988V162.01Z" fill="#B286D9" />
        <path d="M101.196 162.01s13.248 4.15 41.175-11.054c21.846-11.901 45.923-7.292 45.923-7.292" stroke="#93278F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M107.691 163.777c.776 0 1.404-.616 1.404-1.375s-.628-1.375-1.404-1.375c-.775 0-1.403.616-1.403 1.375s.628 1.375 1.403 1.375Z" fill="#F15A24" />
        <path d="M81.805 160.333s8.056.26 18.14-2.531c10.185-2.82 24.881-9.497 38.141-10.121 17.073-.826 39.743 6.731 50.411 11.562" stroke="#662D91" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M99.586 156.332c9.902-1.239 25.89 11.459 44.54 9.423 18.489-2.019 29.513-15.807 44.069-15.026" stroke="#F7931E" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M92.118 164.38h-.096a.033.033 0 0 0-.033.033v.099c0 .018.015.033.033.033h.096a.033.033 0 0 0 .033-.033v-.099a.033.033 0 0 0-.033-.033Z" fill="#fff" />
        <path d="M308.482 71.654h-95.999c-3.264 0-5.911 2.64-5.911 5.897v57.284c0 3.257 2.647 5.897 5.911 5.897h95.999c3.265 0 5.912-2.64 5.912-5.897V77.55c0-3.256-2.647-5.896-5.912-5.896Z" fill="#794CA1" />
        <path d="M302.367 83.711h-83.525a2.104 2.104 0 0 0-2.107 2.102v.004c0 1.161.943 2.102 2.107 2.102h83.525a2.105 2.105 0 0 0 2.108-2.102v-.004c0-1.16-.944-2.102-2.108-2.102ZM302.367 93.023h-83.525a2.104 2.104 0 0 0-2.107 2.101v.356c0 1.16.943 2.101 2.107 2.101h83.525a2.105 2.105 0 0 0 2.108-2.102v-.355c0-1.16-.944-2.101-2.108-2.101Z" fill="#D2E6FF" />
        <path d="M236.171 107.624h-11.334a2.36 2.36 0 0 0-2.364 2.357v15.27a2.36 2.36 0 0 0 2.364 2.358h11.334a2.36 2.36 0 0 0 2.364-2.358v-15.27a2.36 2.36 0 0 0-2.364-2.357Z" fill="#EEF6FF" style="mix-blend-mode:hard-light" />
        <path d="M230.587 110.134h-4.364a1.832 1.832 0 0 0-1.834 1.829v6.335c0 1.01.821 1.829 1.834 1.829h4.364a1.831 1.831 0 0 0 1.833-1.829v-6.335c0-1.01-.821-1.829-1.833-1.829Z" fill="#7C37B0" />
        <path d="M229.597 104.601h-2.181c-.677 0-1.226.547-1.226 1.222v9.023c0 .675.549 1.222 1.226 1.222h2.181c.677 0 1.226-.547 1.226-1.222v-9.023c0-.675-.549-1.222-1.226-1.222Z" stroke="#B688E2" stroke-width="2" stroke-miterlimit="10" />
        <path d="M261.888 107.624h-11.335a2.36 2.36 0 0 0-2.364 2.357v15.27a2.362 2.362 0 0 0 2.364 2.358h11.335a2.36 2.36 0 0 0 2.364-2.358v-15.27a2.36 2.36 0 0 0-2.364-2.357Z" fill="#EEF6FF" style="mix-blend-mode:hard-light" />
        <path d="M256.308 110.134h-4.364a1.832 1.832 0 0 0-1.834 1.829v6.335c0 1.01.821 1.829 1.834 1.829h4.364a1.832 1.832 0 0 0 1.834-1.829v-6.335c0-1.01-.821-1.829-1.834-1.829Z" fill="#7C37B0" />
        <path d="M255.314 104.601h-2.182c-.677 0-1.225.547-1.225 1.222v9.023c0 .675.548 1.222 1.225 1.222h2.182c.677 0 1.225-.547 1.225-1.222v-9.023c0-.675-.548-1.222-1.225-1.222Z" stroke="#B688E2" stroke-width="2" stroke-miterlimit="10" />
        <path d="M49.15 277.81h8.015c1.434 0 2.81.568 3.823 1.579a5.387 5.387 0 0 1 1.584 3.813v6.702H43.744v-6.702c0-1.43.57-2.801 1.583-3.813a5.415 5.415 0 0 1 3.823-1.579Z" fill="#E67A53" />
        <path d="m60.845 108.54-3.858 22.133 13.04 3.018 3.855-22.136-13.037-3.015Z" fill="#AF84F9" />
        <path d="M90.577 93.68a47.998 47.998 0 0 1-5.626 18.928 39.517 39.517 0 0 1-2.07 3.414l-.128.182c-2.484 3.568-5.013 5.591-6.587 5.244-3.99-.879-31.686-18.028-30.858-33.405.745-13.569 12.672-20.605 25.162-19.218 12.49 1.388 21.06 12.02 20.107 24.854Z" fill="#C8A6FF" />
        <path d="M82.128 104.63c-.364.483-5.601 1.742-10.834-1.144-4.26-2.349-5.382-7.676-3.46-10.439 1.92-2.762 6.793-2.836 10.394.413 4.339 3.977 4.36 10.567 3.9 11.17Z" fill="#0A1A5B" />
        <path d="M85.498 111.571a61.4 61.4 0 0 1-.546 1.037 39.492 39.492 0 0 1-2.07 3.414c-4.223-.805-5.535-6.478-5.535-6.478 1.95.413 5.27 1.321 8.151 2.027ZM80.148 98.245c.249-.186.055-.862-.433-1.51-.488-.646-1.085-1.02-1.333-.833-.249.186-.055.862.433 1.509.488.647 1.085 1.02 1.333.834Z" fill="#fff" />
        <path d="M88.843 139.72c2.898 5.335 4.819 13.874 5.936 20.415l8.437-3.931 4.658 7.325-16.85 10.53c-4.371 1.883-7.24 1.127-7.24 1.127l-13.28-44.637s13.085-.434 18.339 9.171Z" fill="#E67A53" />
        <path opacity=".62" d="M88.843 139.72c2.898 5.335 4.819 13.874 5.936 20.415l8.437-3.931 4.658 7.325-16.85 10.53c-4.371 1.883-7.24 1.127-7.24 1.127l-13.28-44.637s13.085-.434 18.339 9.171Z" fill="#A145C4" />
        <path d="M108.107 163.409c1.809-1.175 1.854-4.305.1-6.993-1.753-2.687-4.641-3.913-6.45-2.739-1.81 1.175-1.854 4.305-.1 6.992 1.753 2.688 4.641 3.914 6.45 2.74Z" fill="#943A52" />
        <path d="M82.815 205.474h-37.18c-.34-6.71.27-16.183-.29-25.345-.115-1.85-.253-3.74-.413-5.67a356.046 356.046 0 0 0-1.156-11.363c-.505-4.344-1.076-8.791-1.655-13.288.368-9.369 5.646-17.198 12.833-20.271l.083-.028a16.87 16.87 0 0 1 7.307-1.351l3.361.132c10.673.413 18.928 10.79 18.435 23.169.103 20.581.128 39.765-1.325 54.015Z" fill="#E67A53" />
        <path d="M54.892 131.453h19.341v-7.073h-19.34v7.073Z" fill="#E6771E" />
        <path d="M26.956 175.611c1.594 1.285 4.037 1.338 6.185 1.181 2.563-.181 8.648-3.555 8.648-3.555l21.346-12.445-3.221-9.353.053-4.584-15.222 8.197s13.48-10.282 8.106-24.875l-.083.029c-1.163.351-13.765 4.542-22.33 22.236-6.504 13.424-6.86 20.432-3.482 23.169Z" fill="#DB5A33" />
        <path d="M58.883 154.552c.238 1.09.628 2.141 1.16 3.122 1.398 2.511 3.203 3.056 3.203 3.056s1.52-1.219.861-5.695c-.07-.474-.16-.982-.285-1.536a12.889 12.889 0 0 0-3.126-6.127 1.179 1.179 0 0 0-.828-.521c-.302.083-.89.545-1.188 1.726-.34 1.297-.414 3.275.203 5.975Z" fill="#AD3B18" />
        <path d="M84.927 278.908c.014.414-.004.828-.054 1.239H71.348a6.423 6.423 0 0 1-.07-.483c-.828-21.472-2.642-42.118-5.887-61.716a361.89 361.89 0 0 0-2.252-12.28l3.076-.789 17.035-4.373c2.347 24.004 2.215 51.298 1.677 78.402Z" fill="#E67A53" />
        <path d="m68.247 204.388-2.028 13.498-6.326 62.248H45.532l.016-77.361 22.699 1.615Z" fill="#E67A53" />
        <path d="M64.24 216.136c.604 0 20.099-6.285 19.068-18.648-.559-6.665-9.965-4.901-9.965-4.901l-11.96 4.699 2.857 18.85ZM71.278 275.522h17.9a6.263 6.263 0 0 1 4.418 1.825 6.237 6.237 0 0 1 1.83 4.406v5.525H71.278v-11.756Z" fill="#E67A53" />
        <path d="M84.806 191.269H44.51v1.817h40.297v-1.817ZM84.806 194.469H44.51v1.632h40.297v-1.632Z" fill="#E1E1F4" />
        <path opacity=".52" d="m124.21 131.007-20.153-42.716-43.307 46.573 23.845 27.005 39.615-30.862Z" fill="url(#paint11_linear_469_2577)" />
        <path d="m124.209 131.016-3.788 2.663-16.054 25.457-9.795 1.362-8.772.413-1.192.946-.654-.438a.712.712 0 0 1-.207-.974l17.904-28.459a2.315 2.315 0 0 1 1.785-1.069l19.382-1.384a.685.685 0 0 1 .617.302l.774 1.181Z" fill="#8C5DB0" />
        <path d="m124.21 131.016-19.044 28.004-.828.116-9.766 1.362-9.989 1.396v-.025l17.988-28.025a3.183 3.183 0 0 1 2.483-1.462l19.156-1.366Z" fill="#7C37B0" />
        <path d="M111.906 149.226s-.327-.591-2.219-1.181c-1.892-.591-4.554-1.239-5.514-.112-.749.896 1.3 2.04 2.219 2.478-2.815-.413-6.773-.711-6.893.462-.091.884.956 1.495 2.26 1.912-2.16-.045-4.036.239-4.06 1.338 0 .685.695 1.173 1.808 1.544-1.002.132-1.722.475-1.813 1.165-.248 1.87 7.982 2.964 9.936 2.956 1.37 0 1.622-1.759 1.146-2.56 1.52.26 1.917-1.912 1.379-2.709a.825.825 0 0 0-.17-.169c1.503.272 1.577-1.681 1.064-2.478 1.242-.21 1.279-1.916.857-2.646Z" fill="#C5A1FF" />
        <path d="M32.89 73.05h-.03l.323 3.489c.112 1.164.228 2.333.29 3.497l.414 7.02.828 14.006.766 14.007.72 13.989-1.002-.982 14.332.107 14.328.14 14.332.161 14.332.207-1.424 1.239 1.557-13.552a1685 1685 0 0 1 1.594-13.553l1.614-13.548 1.631-13.543 1.292 1.51h-.029l-1.432-.07.136-1.403A21.906 21.906 0 0 0 95.6 64.62a27.037 27.037 0 0 0-6.98-9.137 33.551 33.551 0 0 0-10.055-5.938 39.375 39.375 0 0 0-11.534-2.428c-3.963-.232-7.94.15-11.786 1.131a34.401 34.401 0 0 0-10.825 4.82 30.163 30.163 0 0 0-4.554 3.831 25.124 25.124 0 0 0-3.664 4.745 23.63 23.63 0 0 0-2.414 5.475 23.068 23.068 0 0 0-.898 5.93Zm0 0c-.063-2.03.14-4.06.603-6.037a22.906 22.906 0 0 1 2.195-5.695 27.92 27.92 0 0 1 8.097-9.146 35.01 35.01 0 0 1 11.066-5.392 40.363 40.363 0 0 1 24.462.76 35.68 35.68 0 0 1 10.867 6.127 28.986 28.986 0 0 1 7.758 9.885 24.573 24.573 0 0 1 2.236 12.462l-1.292-1.478h.03l1.456.054-.165 1.453-1.54 13.556-1.561 13.553c-.517 4.521-1.035 9.039-1.581 13.552l-1.615 13.548-.153 1.267-1.271-.024-14.328-.294-14.328-.338-14.328-.359-14.328-.393-.981-.029-.025-.953-.36-14.019-.323-14.019-.273-14.04-.112-7.019c0-1.173-.058-2.333-.103-3.501l-.145-3.506h.041v.025Z" fill="#E6E6E6" />
        <path d="M89.605 141.938c-.294-.599-7.672-.525-7.672-.525l-5.932-.26-5.535 2.011-9.737 4.096-2.049 1.173c-.344 1.276-.451 3.254.124 5.979a11.85 11.85 0 0 0 1.106 3.142c1.266-.78 2.848-1.784 4.106-2.572 1.259-.789 2.124-1.322 2.17-1.305.149.041 4.14 1.086 4.475 1.123h.09l2.866.743a5.397 5.397 0 0 0 4.326-.9A280.573 280.573 0 0 0 81.499 152a3.464 3.464 0 0 0 .716-4.711c-.393-.57-.696-1.012-.708-1.049-.012-.037-2.07-.826-3.366-1.346l3.792-.475s4.24.182 6.086-.545c1.846-.727 1.888-1.342 1.586-1.936Z" fill="#BB91FF" />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_469_2577"
        x1="0"
        y1="170"
        x2="28"
        y2="170"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C4C4C4" stop-opacity="0" />
        <stop offset="1" stop-color="#C4C4C4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_469_2577"
        x1="212"
        y1="287"
        x2="212"
        y2="314"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C4C4C4" />
        <stop offset="1" stop-color="#C4C4C4" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_469_2577"
        x1="-21.136"
        y1="191.608"
        x2="414.983"
        y2="191.608"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".01" stop-color="#DC7E54" />
        <stop offset=".08" stop-color="#CA725B" />
        <stop offset=".23" stop-color="#9C526E" />
        <stop offset=".4" stop-color="#602A87" />
        <stop offset=".47" stop-color="#58277F" />
        <stop offset=".57" stop-color="#412068" />
        <stop offset=".64" stop-color="#2E1954" />
        <stop offset=".78" stop-color="#2D1951" />
        <stop offset=".87" stop-color="#2A1848" />
        <stop offset=".95" stop-color="#26163A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_469_2577"
        x1="-21.136"
        y1="191.608"
        x2="426"
        y2="191.608"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".01" stop-color="#DC7E54" />
        <stop offset=".08" stop-color="#CA725B" />
        <stop offset=".23" stop-color="#9C526E" />
        <stop offset=".4" stop-color="#602A87" />
        <stop offset=".47" stop-color="#58277F" />
        <stop offset=".57" stop-color="#412068" />
        <stop offset=".699" stop-color="#2E1954" />
        <stop offset=".78" stop-color="#2D1951" />
        <stop offset=".87" stop-color="#2A1848" />
        <stop offset=".95" stop-color="#26163A" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_469_2577"
        x1="208.683"
        y1="181.157"
        x2="233.269"
        y2="181.157"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".76" stop-color="#E9ECF4" />
        <stop offset=".97" stop-color="#E9ECF4" />
        <stop offset="1" stop-color="#E9ECF4" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_469_2577"
        x1="15190"
        y1="8144.43"
        x2="15194.8"
        y2="8479.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".76" stop-color="#E9ECF4" />
        <stop offset=".97" stop-color="#E9ECF4" />
        <stop offset="1" stop-color="#E9ECF4" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_469_2577"
        x1="221.235"
        y1="174.819"
        x2="220.703"
        y2="161.142"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".19" stop-color="#952AB4" />
        <stop offset=".25" stop-color="#8928AC" />
        <stop offset=".36" stop-color="#682297" />
        <stop offset=".45" stop-color="#471D82" />
        <stop offset=".62" stop-color="#231047" />
        <stop offset=".68" stop-color="#160B32" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_469_2577"
        x1="214.234"
        y1="165.235"
        x2="227.064"
        y2="165.235"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".11" stop-color="#1FB4E8" />
        <stop offset=".84" stop-color="#1B1464" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_469_2577"
        x1="110.763"
        y1="79.545"
        x2="115.313"
        y2="79.545"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".01" stop-color="#649FF4" />
        <stop offset=".21" stop-color="#6593F1" />
        <stop offset=".59" stop-color="#6674EA" />
        <stop offset="1" stop-color="#684BE0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_469_2577"
        x1="105.849"
        y1="67.777"
        x2="108.846"
        y2="75.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset=".06" stop-color="#F0EBFF" />
        <stop offset=".17" stop-color="#DBCFFF" />
        <stop offset=".28" stop-color="#CBF" />
        <stop offset=".38" stop-color="#C3AFFF" />
        <stop offset=".49" stop-color="#C0ABFF" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_469_2577"
        x1="3716.1"
        y1="1655.28"
        x2="3632.21"
        y2="1789.64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset=".06" stop-color="#F0EBFF" />
        <stop offset=".17" stop-color="#DBCFFF" />
        <stop offset=".28" stop-color="#CBF" />
        <stop offset=".38" stop-color="#C3AFFF" />
        <stop offset=".49" stop-color="#C0ABFF" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_469_2577"
        x1="81.921"
        y1="110.861"
        x2="117.241"
        y2="163.448"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".01" stop-color="#649FF4" stop-opacity="0" />
        <stop offset=".02" stop-color="#6CA4F5" stop-opacity=".05" />
        <stop offset=".09" stop-color="#8EB9F7" stop-opacity=".27" />
        <stop offset=".17" stop-color="#ACCCF9" stop-opacity=".47" />
        <stop offset=".24" stop-color="#C6DCFB" stop-opacity=".63" />
        <stop offset=".32" stop-color="#DBE8FC" stop-opacity=".76" />
        <stop offset=".4" stop-color="#EBF2FE" stop-opacity=".87" />
        <stop offset=".48" stop-color="#F6F9FE" stop-opacity=".94" />
        <stop offset=".56" stop-color="#FDFEFF" stop-opacity=".99" />
        <stop offset=".64" stop-color="#fff" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<template>
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity=".5" d="m2.008 20.317 8.659-8.659a.417.417 0 0 1 .291-.125h9.875l-9.483 9.492H2.308a.417.417 0 0 1-.291-.708h-.009ZM19.717 29.4l9.491-9.492v9.875a.417.417 0 0 1-.125.292l-8.65 8.667a.417.417 0 0 1-.708-.292l-.008-9.05Z" fill="url(#paint0_linear_356_1208)" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.309 3.442c-.434-.442-4.417-.5-9.109.875a25.133 25.133 0 0 0-10.7 6.1 35.832 35.832 0 0 0-5.458 6.9 11.716 11.716 0 0 1 11.383 11.4 36.19 36.19 0 0 0 6.9-5.45 25.133 25.133 0 0 0 6.1-10.717v-.008a11.816 11.816 0 0 0-2.94-5.103 11.816 11.816 0 0 1 2.949 5.111c1.383-4.7 1.308-8.667.875-9.108Z" fill="url(#paint1_linear_356_1208)" />
    <path d="M12.183 28.567c-5.608-5.609-6.141 6.141-6.666 6.666.525-.566 12.275-1.066 6.666-6.666Z" fill="url(#paint2_linear_356_1208)" />
    <path d="m26.108 14.717 5.584-5.584a3.95 3.95 0 0 0-5.584 5.584Z" fill="url(#paint3_linear_356_1208)" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.308 3.442c-.433-.442-4.417-.5-9.108.875a25.134 25.134 0 0 0-10.7 6.1 36.197 36.197 0 0 0-5.45 6.9A11.667 11.667 0 0 1 20 20.75l13.4-13.4 3.908-3.908Z" fill="url(#paint4_linear_356_1208)" />
    <path d="M23.425 28.708a20.2 20.2 0 0 1-7.433 2.892 11.75 11.75 0 0 0-6.825-6.842 20.342 20.342 0 0 1 2.883-7.441 11.725 11.725 0 0 1 11.375 11.391Z" fill="url(#paint5_linear_356_1208)" />
    <path d="M12.042 17.317a20.2 20.2 0 0 0-2.875 7.441 11.743 11.743 0 0 1 4.167 2.692L20 20.783a11.583 11.583 0 0 0-7.958-3.466Z" fill="#F87900" />
    <path d="M31.69 14.711a3.95 3.95 0 1 0-5.586-5.586 3.95 3.95 0 0 0 5.586 5.586Z" fill="#2E1F42" />
    <defs>
      <linearGradient
        id="paint0_linear_356_1208"
        x1="1.897"
        y1="38.861"
        x2="29.208"
        y2="38.861"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F87900" />
        <stop offset=".511" stop-color="#FE4F45" />
        <stop offset="1" stop-color="#E419BA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_356_1208"
        x1="35.262"
        y1="-12.26"
        x2="23.613"
        y2="35.824"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" />
        <stop offset=".51" stop-color="#F87900" />
        <stop offset="1" stop-color="#AE35DD" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_356_1208"
        x1="-.206"
        y1="33.544"
        x2="9.817"
        y2="42.233"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" />
        <stop offset=".51" stop-color="#F87900" />
        <stop offset="1" stop-color="#AE35DD" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_356_1208"
        x1="20.384"
        y1="13.343"
        x2="28.536"
        y2="20.41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" />
        <stop offset=".51" stop-color="#F87900" />
        <stop offset="1" stop-color="#AE35DD" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_356_1208"
        x1="42.166"
        y1="1"
        x2="13.117"
        y2="61.517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F87900" />
        <stop offset=".511" stop-color="#FE4F45" />
        <stop offset="1" stop-color="#E419BA" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_356_1208"
        x1="9.167"
        y1="31.6"
        x2="23.425"
        y2="31.6"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F87900" />
        <stop offset=".511" stop-color="#FE4F45" />
        <stop offset="1" stop-color="#E419BA" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

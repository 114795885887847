<template lang="pug">
.lp-card
  .lp-card__container
    .lp-card__illustration
      IllustrationSvg.lp-card__svg
    .lp-card__content
      h3.lp-card__title
        Icon.lp-card__icon
        template {{ $t('pages.ssr_landing.manage.title') }}
      i18n.ready__content-text-description(
        tag="p"
        path='pages.ssr_landing.manage.text'
        class="lp-card__text"
      )
        template(v-slot:easyToUse)
          strong {{ $t('pages.ssr_landing.manage.easyToUse') }}
        template(v-slot:socialMediaLink)
          Link(:href="$locatedLink(socialMediaLink)") {{ $t('pages.ssr_landing.manage.socialMediaLink') }}
        template(v-slot:externalWebsitesLink)
          Link(:href="$locatedLink(externalWebsitesLink)") {{ $t('pages.ssr_landing.manage.externalWebsitesLink') }}
</template>
<script>
import Icon from './svg/Icon.vue'
import IllustrationSvg from './svg/Illustration'

import Link from '@/components/common/Link/Link'

export default {
  components: {
    Icon,
    IllustrationSvg,
    Link
  },

  data () {
    return {
      socialMediaLink: '/features/social-media-sharing/',
      externalWebsitesLink: '/features/embeddable-player/'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <svg width="2292" height="370" viewBox="0 0 2292 370" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_535_5169)">
      <path opacity=".2" d="m2434.49 475.487-12.08-.488a42171.7 42171.7 0 0 0-8.68-91.589L766.901 353.434l-780.409-14.159-6.967-.097 6.967-63.273v-41.693l-.995.293-2.133.586 1.138-9.862 2.132-19.04 2.417-21.775s121.846-58.488 272.411-64.541c97.96-3.906 217.388 49.309 305.111 46.77 87.724-2.538 127.107-64.346 220.09-81.14 92.984-16.795 269.847 63.467 364.117 40.716 94.26-22.75 124.12-168.921 243.83-186.595 119.71-17.673 326.72 98.229 326.72 98.229s615.06-256.214 632.83-256.898c9.24-.39 30.29 184.545 48.63 363.719.42 4.101.85 8.104 1.28 12.205l.85 7.909c8.25 81.922 15.92 161.013 21.33 219.012 4.97 55.657 8.24 91.687 8.24 91.687Z" fill="url(#paint0_linear_535_5169)" />
      <path opacity=".2" d="m2438.19 511.517-12.51-.488c-.86-9.081-1.99-21.286-3.27-35.933-2.28-24.41-5.26-55.851-8.68-91.588L766.901 353.434l-780.409-14.158-6.967-.098 6.967-63.272 4.692-42.866 1.137-11.033 1.564-14.256s137.485-66.495 288.05-60.734c123.41 4.687 200.328 58.195 288.051 55.657 87.723-2.539 136.49-66.495 229.615-83.192 92.984-16.794 283.639 87.488 377.909 64.737 94.26-22.751 100.94-190.793 220.66-208.467 119.57-17.77 326.72 98.131 326.72 98.131s615.06-256.214 632.83-256.898c8.53-.293 27.58 160.72 44.93 327.787.42 4.1.85 8.104 1.27 12.205l.86 7.909c8.1 78.309 15.64 156.619 21.61 219.012 7.11 75.478 11.8 127.619 11.8 127.619Z" fill="url(#paint1_linear_535_5169)" />
      <path opacity=".2" d="M2413.88 383.508 766.901 353.434l-780.409-14.159-6.967-.097 6.967-63.273v-41.693l-.995.293-2.133.586c-.426.097-.853.195-1.137.39l1.137-9.764 1.28-.488c.569-.195 1.28-.488 2.132-.879 1.422-.586 3.413-1.465 5.972-2.441 30.426-12.01 132.509-48.626 258.051-53.703 97.96-3.906 272.979 85.046 354.02 62.1 78.055-22.067 102.367-58.195 198.479-60.343 130.234-2.831 288.622 66.397 382.882 43.549 94.26-22.849 193.36-176.245 313.22-193.918 119.85-17.674 214.97 72.06 214.97 72.06s615.05-256.214 632.83-256.898c12.37-.39 47.2 344.874 66.68 548.752Z" fill="url(#paint2_linear_535_5169)" />
      <path d="M506.561 355.386s-67.374 16.495-76.064 14.151c-1.172-.292-11.912 1.563-29.586 0-82.41-7.225-315.58 9.276-433.24 0v-30.262l-4.784-.097 4.784-63.273v-41.693l3.222-1.172c36.226-12.107 77.92 3.711 98.62 19.529 35.932 27.437 49.211 51.457 83.484 55.949 6.249.781 12.596.976 18.845.586 49.309-2.734 38.862-35.152 85.828-60.05 2.343-1.27 4.784-2.344 7.225-3.32 45.013-18.162 92.663 12.303 92.663 12.303l146.073 95.592 2.93 1.757Z" fill="url(#paint3_linear_535_5169)" />
      <path d="M2306.96 316.134v53.606H69.242c362.409-58.683 740.031-87.683 1113.668-96.08 71.23-1.563 142.46-2.441 213.84-2.344 33.84 0 67.39.195 100.94.488 236.59 2.441 465.49 13.67 683.45 32.515 42.37 3.613 84.31 7.519 125.82 11.815Z" fill="url(#paint4_linear_535_5169)" />
      <path d="M1485.75 278.054c16.57 0 30-.306 30-.684 0-.377-13.43-.683-30-.683s-30 .306-30 .683c0 .378 13.43.684 30 .684ZM1202.96 285.865c32.98 0 59.72-1.137 59.72-2.539 0-1.402-26.74-2.539-59.72-2.539-32.98 0-59.71 1.137-59.71 2.539 0 1.402 26.73 2.539 59.71 2.539Z" fill="#35185E" />
      <path d="M813.281 297.51c29.381-1.355 53.143-3.019 53.075-3.719-.069-.699-23.942-.169-53.323 1.186-29.381 1.354-53.144 3.019-53.075 3.718.068.7 23.942.169 53.323-1.185Z" fill="#361269" />
      <path d="M2184.8 333.972c.27-3.23-47.55-7.762-106.81-10.123-59.26-2.361-107.52-1.657-107.8 1.573-.27 3.23 47.55 7.763 106.81 10.124 59.26 2.361 107.53 1.656 107.8-1.574Z" fill="#210B40" />
      <path d="M1394.62 317.403c23.95 0 43.36-1.442 43.36-3.222s-19.41-3.222-43.36-3.222-43.37 1.442-43.37 3.222 19.42 3.222 43.37 3.222Z" fill="#2A124A" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_535_5169"
        x1="479.932"
        y1="-268.773"
        x2="2107.07"
        y2="1382.35"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F56088" />
        <stop offset=".03" stop-color="#E1598D" stop-opacity=".85" />
        <stop offset=".07" stop-color="#C75093" stop-opacity=".65" />
        <stop offset=".12" stop-color="#B04899" stop-opacity=".48" />
        <stop offset=".17" stop-color="#9C429D" stop-opacity=".33" />
        <stop offset=".22" stop-color="#8D3CA1" stop-opacity=".21" />
        <stop offset=".27" stop-color="#8038A4" stop-opacity=".12" />
        <stop offset=".33" stop-color="#7835A6" stop-opacity=".05" />
        <stop offset=".39" stop-color="#7334A8" stop-opacity=".01" />
        <stop offset=".48" stop-color="#7133A8" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_535_5169"
        x1="487.633"
        y1="-247.943"
        x2="2121.59"
        y2="1410.11"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F56088" />
        <stop offset=".03" stop-color="#E1598D" stop-opacity=".85" />
        <stop offset=".07" stop-color="#C75093" stop-opacity=".65" />
        <stop offset=".12" stop-color="#B04899" stop-opacity=".48" />
        <stop offset=".17" stop-color="#9C429D" stop-opacity=".33" />
        <stop offset=".22" stop-color="#8D3CA1" stop-opacity=".21" />
        <stop offset=".27" stop-color="#8038A4" stop-opacity=".12" />
        <stop offset=".33" stop-color="#7835A6" stop-opacity=".05" />
        <stop offset=".39" stop-color="#7334A8" stop-opacity=".01" />
        <stop offset=".48" stop-color="#7133A8" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_535_5169"
        x1="483.542"
        y1="-238.367"
        x2="2021.47"
        y2="1322.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F56088" />
        <stop offset=".03" stop-color="#E1598D" stop-opacity=".85" />
        <stop offset=".07" stop-color="#C75093" stop-opacity=".65" />
        <stop offset=".12" stop-color="#B04899" stop-opacity=".48" />
        <stop offset=".17" stop-color="#9C429D" stop-opacity=".33" />
        <stop offset=".22" stop-color="#8D3CA1" stop-opacity=".21" />
        <stop offset=".27" stop-color="#8038A4" stop-opacity=".12" />
        <stop offset=".33" stop-color="#7835A6" stop-opacity=".05" />
        <stop offset=".39" stop-color="#7334A8" stop-opacity=".01" />
        <stop offset=".48" stop-color="#7133A8" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_535_5169"
        x1="180.396"
        y1="106.076"
        x2="332.25"
        y2="809.053"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".05" stop-color="#9133C2" />
        <stop offset=".37" stop-color="#26163A" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_535_5169"
        x1="1169.29"
        y1="-274.202"
        x2="1185.72"
        y2="866.195"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".43" stop-color="#9133C2" />
        <stop offset=".54" stop-color="#26163A" />
      </linearGradient>
      <clipPath id="clip0_535_5169">
        <path fill="#fff" d="M0 0h2292v370H0z" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<template lang="pug">
.lp-card
  .lp-card__container
    .lp-card__illustration
      IllustrationSvg.lp-card__svg
    .lp-card__content
      h3.lp-card__title
        Icon.lp-card__icon
        template {{ $t('pages.ssr_landing.distribute.title') }}
      i18n.ready__content-text-description(
        tag="p"
        path='pages.ssr_landing.distribute.text'
        class="lp-card__text"
      )
        template(v-slot:oneTimeSubmission)
          strong {{ $t('pages.ssr_landing.distribute.oneTimeSubmission') }}
        template(v-slot:automaticDistributionLink)
          strong
            Link(:href="$locatedLink(automaticDistributionLink)") {{ $t('pages.ssr_landing.distribute.automaticDistributionLink') }}
        template(v-slot:allPlatforms)
          strong {{ $t('pages.ssr_landing.distribute.allPlatforms') }}
</template>

<script>
import Icon from './svg/Icon'
import IllustrationSvg from './svg/Illustration'

import Link from '@/components/common/Link/Link'

export default {
  components: {
    Icon,
    IllustrationSvg,
    Link
  },

  data () {
    return {
      automaticDistributionLink: '/features/podcast-distribution/'
    }
  }
}
</script>

<style lang="scss" scoped>
.lp-card {
  &__container {
    flex-direction: row-reverse;

    @include display-less(tablet) {
      flex-direction: column-reverse;
    }
  }

  &__illustration {
    padding: 0;
  }

  &__svg {
    bottom: 0;
    left: 40px;
    position: absolute;

    @include display-less(tablet) {
      width: 100%;
      height: auto;
      position: static;
    }
  }
}
</style>

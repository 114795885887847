<template>
  <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_356_1201)">
      <path d="M24 34V23a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v11a1 1 0 0 1-1 1H8a3 3 0 0 1-3-3V11a6 6 0 0 1 6-6h18a6 6 0 0 1 6 6v21a3 3 0 0 1-3 3h-7a1 1 0 0 1-1-1Z" fill="url(#paint0_linear_356_1201)" />
      <path d="M30 0a4 4 0 0 1 3.2 1.6L39 9.35A3.999 3.999 0 1 1 32.13 13h-.26a4 4 0 0 1-7.74 0h-.26a4 4 0 0 1-7.74 0h-.26a4 4 0 0 1-7.74 0h-.26A4 4 0 1 1 1 9.35l5.93-7.78A4 4 0 0 1 10.11 0H30Z" fill="url(#paint1_linear_356_1201)" fill-opacity=".8" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_356_1201"
        x1="-15.99"
        y1="28.805"
        x2="20.771"
        y2="60.674"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" />
        <stop offset=".51" stop-color="#F87900" />
        <stop offset="1" stop-color="#AE35DD" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_356_1201"
        x1="-.002"
        y1="15.999"
        x2="40.003"
        y2="15.999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F87900" />
        <stop offset=".511" stop-color="#FE4F45" />
        <stop offset="1" stop-color="#E419BA" />
      </linearGradient>
      <clipPath id="clip0_356_1201">
        <path fill="#fff" d="M0 0h40v35H0z" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<template>
  <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_356_1816)">
      <path d="m1.59 37.12 12.636-6.564c.196-.1.354-.263.45-.461l10.76-22.698a1.025 1.025 0 0 1 1.6-.338L39.65 17.972a1.024 1.024 0 0 1 .349.78v15.62a4.103 4.103 0 0 1-4.102 4.102H1.918a.717.717 0 0 1-.328-1.353Z" fill="url(#paint0_linear_356_1816)" />
      <path d="M.78 36.013 12.41 20.28a1.027 1.027 0 0 1 1.118-.38l11.949 3.487a1.026 1.026 0 0 0 1.2-.513L38.05 1.141a1.026 1.026 0 0 1 1.939.472v32.759a4.102 4.102 0 0 1-4.103 4.102H2.02a1.538 1.538 0 0 1-1.23-2.461H.78Z" fill="url(#paint1_linear_356_1816)" fill-opacity=".8" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_356_1816"
        x1="-25.938"
        y1="31.935"
        x2="13.19"
        y2="73.493"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" />
        <stop offset=".51" stop-color="#F87900" />
        <stop offset="1" stop-color="#AE35DD" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_356_1816"
        x1=".482"
        y1="38.474"
        x2="39.99"
        y2="38.474"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F87900" />
        <stop offset=".511" stop-color="#FE4F45" />
        <stop offset="1" stop-color="#E419BA" />
      </linearGradient>
      <clipPath id="clip0_356_1816">
        <path fill="#fff" transform="translate(0 .526)" d="M0 0h40v37.949H0z" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

import { config } from '@/config'

export const schemaMarkup = {
  '@context': 'http://schema.org',
  '@type': 'LocalBusiness',
  '@id': 'https://www.reviews.io/company-reviews/store/rss-com',
  name: 'RSS.com',
  alternateName: 'RSS․com',
  description:
    'RSS.com is a podcast hosting, distribution, and monetization platform designed for podcasters of all experience levels. RSS.com makes it simple to start, track, and grow a podcast.',
  url: config.baseUrl,
  image: {
    '@type': 'ImageObject',
    url: config.baseUrl + '/icon@2x.png',
    width: 512,
    height: 512
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    bestRating: '5',
    ratingValue: '4.92',
    worstRating: '1',
    reviewCount: '1998'
  },
  foundingDate: '2018',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '16192 Coastal Highway',
    addressLocality: 'Lewes',
    addressCountry: 'US',
    postalCode: '19958'
  },
  sameAs: [
    'https://www.reviews.io/company-reviews/store/rss-com',
    'https://x.com/rss',
    'https://www.facebook.com/rss.podcasting',
    'https://www.linkedin.com/company/rsscom',
    'https://www.instagram.com/rss_podcasting',
    'https://bsky.app/profile/rss.com'
  ],
  review: [
    {
      '@type': 'Review',
      itemReviewed: {
        '@type': 'Organization',
        name: 'RSS.com',
        image: 'https://www.reviews.io/logo-image/rss-com'
      },
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: '',
        sameAs: ''
      },
      datePublished: '2024-12-08 19:43:29',
      reviewBody: 'Easy to use',
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        worstRating: '1',
        ratingValue: '5'
      }
    },
    {
      '@type': 'Review',
      itemReviewed: {
        '@type': 'Organization',
        name: 'RSS.com',
        image: 'https://www.reviews.io/logo-image/rss-com'
      },
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: '',
        sameAs: ''
      },
      datePublished: '2024-12-07 08:03:47',
      reviewBody: 'My first Podcast. So far so good :-)',
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        worstRating: '1',
        ratingValue: '5'
      }
    },
    {
      '@type': 'Review',
      itemReviewed: {
        '@type': 'Organization',
        name: 'RSS.com',
        image: 'https://www.reviews.io/logo-image/rss-com'
      },
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: '',
        sameAs: ''
      },
      datePublished: '2024-12-05 01:41:38',
      reviewBody: 'Easy to Use. love it.',
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        worstRating: '1',
        ratingValue: '5'
      }
    },
    {
      '@type': 'Review',
      itemReviewed: {
        '@type': 'Organization',
        name: 'RSS.com',
        image: 'https://www.reviews.io/logo-image/rss-com'
      },
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: '',
        sameAs: ''
      },
      datePublished: '2024-12-02 07:21:24',
      reviewBody:
        'I am so grateful to RSS.com. This is the very first time I have ever made a podcast, and RSS.com made it so easy. Thank you so much, you kindly people!',
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        worstRating: '1',
        ratingValue: '5'
      }
    },
    {
      '@type': 'Review',
      itemReviewed: {
        '@type': 'Organization',
        name: 'RSS.com',
        image: 'https://www.reviews.io/logo-image/rss-com'
      },
      author: {
        '@type': 'Person',
        name: 'Customer'
      },
      publisher: {
        '@type': 'Organization',
        name: '',
        sameAs: ''
      },
      datePublished: '2024-11-30 11:02:39',
      reviewBody: 'This is easy for beginners and fast to load. Impressed with the platform so far. Looking forward to the future.',
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        worstRating: '1',
        ratingValue: '5'
      }
    }
  ]
}
